import { createSelector } from 'reselect';

const projectData = (state) => state.projectData;

export const selectFilterData = () => createSelector([projectData], (state) => state.filter);

export const selectFilterCriteriaData = () =>
  createSelector([projectData], (state) => state.filterCriteria);

export const selectSingleProjectData = () =>
  createSelector([projectData], (state) => state.currentProject);

export const selectStatesData = () => createSelector([projectData], (state) => state.states);
