import React from 'react';
import styles from '../Form.module.scss';

import {
  FormDayPicker,
  FormLabel,
  FormSearchInput,
  FormSelectInput
} from 'components/FormComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormServerAssetSearch from 'components/FormComponents/FormServerAssetSearch/FormServerAssetSearch';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { useFormContext } from 'react-hook-form';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';
import { useParams } from 'react-router-dom';

import { pluck, yesterdayDate } from 'helpers/AppHelpers';

import clsx from 'clsx';

import SectionTitle from '../SectionTitle';
import FormDrCodeAvailabilityInput from 'components/FormComponents/FormInputText/FormDrCodeAvailabilityInput';

export function General() {
  const { id } = useParams();

  const { getValues, setValue, watch, formState, clearErrors } = useFormContext();
  const { errors } = formState;
  const { getAvailableDRCodeAction } = useAssetActions();

  const { creationForm } = useAssetSelector();
  const { rentalStatuses, assetPrefixes, grades, locations, projects } = creationForm;

  const rentalWatcher = watch('rental.rentalStatus');

  const conditions = {
    rentalStatus: {
      disabled: [getValues('isInPicklist')?.name, getValues('isInAssemble')?.name].some(
        (status) => status !== 'No'
      )
    },
    destination: {
      field:
        rentalWatcher?.name === 'InTransit' &&
        getValues('isInPicklist')?.name === 'No' &&
        getValues('isInLeavelist')?.name === 'No',
      label: getValues('isInLeavelist')?.name !== 'No'
    },
    project: {
      display: rentalWatcher?.name === 'Reserved',
      disabled: [getValues('isInPicklist')?.name, getValues('isInAssemble')?.name].some(
        (status) => status !== 'No'
      )
    },
    projectName: rentalWatcher?.name === 'HoldForHigh' || rentalWatcher?.name === 'HoldForLow',
    duration: rentalWatcher?.name === 'HoldForHigh' || rentalWatcher?.name === 'HoldForLow'
  };

  const setDrCode = ({ availableDrCode }) => {
    errors?.drCode?.message && clearErrors('drCode');
    setValue('drCode', availableDrCode);
    setValue('drCodeStatus', { isAvailable: true, status: 'Available!' });
  };
  const setOption = (name, value) => setValue(name, value, { shouldDirty: true });
  const selectOption = ({ name, value }) => setValue(name, value, { shouldDirty: true });

  const selectRental = ({ name, value }) => {
    setOption(name, value);
    errors?.rental?.message && clearErrors('rental');
  };

  const setProject = (name, value) => {
    setOption(name, value);
    errors?.rental?.message && clearErrors('rental');
  };

  const selectAssetPrefix = (name, value) => {
    const q = { prefixId: value.id };
    const fields =
      assetPrefixes
        ?.find(({ id }) => id === value.id)
        ?.assetFields?.map((item) => ({ assetField: item, value: null })) || [];

    getAvailableDRCodeAction(q).then((res) => {
      setDrCode(res);
      setOption(name, value);
      setOption('assetFields', fields);
    });
  };

  const selectContainedInValue = (name, value) => {
    setOption(name, value);
  };

  return (
    <div className={styles.general}>
      <SectionTitle>General Details</SectionTitle>
      <div className={styles.form}>
        <div className={styles.form__block}>
          <div className={styles.form__block_row}>
            <FormLabel required>Name</FormLabel>
            <FormSearchInput
              name="assetPrefix"
              options={assetPrefixes || []}
              onSelect={selectAssetPrefix}
              optionType="prefixType"
            />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel required>DR Code</FormLabel>
            <FormDrCodeAvailabilityInput name="drCode" />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel>Short Note</FormLabel>
            <FormInputText name="shortNote" options={{ max: 255 }} />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel>Contained In</FormLabel>
            <FormServerAssetSearch
              name="containedInAsset"
              onSelect={selectContainedInValue}
              getLabel={(option) => option?.drCode || ''}
              getQuery={(searchQuery) => ({
                pagination: { limit: 40, page: 1 },
                filters: {
                  prefixTypes: [2, 3, 4, 5],
                  isDeleted: false,
                  excludeAssetIds: [...pluck(getValues('contents'), 'id')].concat(id ? [+id] : []),
                  searchQuery
                }
              })}
            />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel>Grade</FormLabel>
            <FormSelectInput
              enrichedByName
              clearable
              name="grade"
              options={grades || []}
              onSelect={selectOption}
            />
          </div>
          <div className={clsx(styles.form__block_row, styles.checkbox)}>
            <FormLabel>Non-owned?</FormLabel>
            <FormCheckbox name="isNonOwned" />
          </div>
        </div>

        <div className={styles.form__block}>
          <div className={styles.form__block_row}>
            <FormLabel>Purchase Date</FormLabel>
            <FormDayPicker name="purchasedAtUtc" />
          </div>
          <div className={clsx(styles.form__block_row, styles.checkbox)}>
            <FormLabel>Labeled?</FormLabel>
            <FormCheckbox name="labeled" />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel>Purchased From</FormLabel>
            <FormInputText name="purchasedFrom" options={{ max: 40 }} />
          </div>
          <div className={styles.form__block_row}>
            <FormLabel required>Rental Status</FormLabel>
            <FormSelectInput
              enrichedByName
              name="rental.rentalStatus"
              options={rentalStatuses || []}
              onSelect={selectRental}
              isDisabled={conditions.rentalStatus.disabled}
            />
          </div>
          {conditions.destination.field && (
            <div className={styles.form__block_row}>
              <FormLabel>Destination</FormLabel>
              <FormSelectInput
                clearable
                name="rental.destination"
                options={locations || []}
                onSelect={selectOption}
              />
            </div>
          )}
          {conditions.project.display && (
            <div className={styles.form__block_row}>
              <FormLabel>Project</FormLabel>
              <FormSearchInput
                clearable
                name="rental.project"
                options={projects || []}
                onSelect={setProject}
                isDisabled={conditions.project.disabled}
              />
            </div>
          )}
          {conditions.projectName && (
            <div className={styles.form__block_row}>
              <FormLabel>Project Name</FormLabel>
              <FormSearchInput
                clearable
                name="rental.project"
                options={projects || []}
                onSelect={setProject}
              />
            </div>
          )}
          {conditions.duration && (
            <div className={styles.form__block_row}>
              <FormLabel>Duration</FormLabel>
              <FormDayPicker name="rental.endDate" min={yesterdayDate} />
            </div>
          )}
          <div className={clsx(styles.form__block_row, styles.info)}>
            <FormLabel>Is in Picklist?</FormLabel>
            <p>{getValues('isInPicklist')?.name}</p>
          </div>
          <div className={clsx(styles.form__block_row, styles.info)}>
            <FormLabel>Is in Leavelist?</FormLabel>
            <p>{getValues('isInLeavelist')?.name}</p>
          </div>
          <div className={clsx(styles.form__block_row, styles.info)}>
            <FormLabel>Is in Assembly?</FormLabel>
            <p>{getValues('isInAssemble')?.name}</p>
          </div>
          {!!getValues('isInLeavelist')?.name && getValues('isInLeavelist')?.name !== 'No' && (
            <div className={clsx(styles.form__block_row, styles.info)}>
              <FormLabel>Destination</FormLabel>
              <p>{getValues('destination')?.siteCode || ''}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
