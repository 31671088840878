import React, { useEffect, useRef, useState } from 'react';
import styles from './components.module.scss';

import DialogPopup from 'components/DialogPopup/DialogPopup';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import BackButton from 'components/BackButton/BackButton';
import MainButton from 'components/StyledComponents/MainButton';

import {
  CREATE_NEW_PREFIX,
  EXIT_WITHOUT_SAVING,
  NEW_PREFIX_TYPE,
  UPDATE_PREFIX,
  UPDATE_PREFIX_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import {
  CATEGORY_DETAILED_PATH,
  CATEGORY_LIST_PATH,
  PREFIX_DETAILED_PATH
} from 'constants/routeConstants';

import { usePrefixActions, usePrefixSelector } from 'hooks/Prefix';
import { useHistory, useParams } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useFormContext } from 'react-hook-form';

import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';
import { getBackendErrors } from 'helpers/AppHelpers';

export default function PrefixEditWrapper({ children }) {
  const { id } = useParams();
  const history = useHistory();
  const isMobile = useMobileViewport();

  const breadcrumbs = useRef([{ name: 'New prefix' }]);

  const [modalData, setModalData] = useState({});

  const { currentPrefix, activeCategory, unsavedFormData } = usePrefixSelector();
  const {
    getPrefixAction,
    getCreationFormAction,
    createPrefixAction,
    updatePrefixAction,
    clearStateAction,
    setUnsavedFormDataAction
  } = usePrefixActions();

  const { formState, getValues, reset, setError } = useFormContext();
  const { isDirty, errors } = formState;

  useEffect(() => {
    if (id && currentPrefix?.id) {
      const checkSubcategories = (data) => (data?.length === 0 ? [{}] : data);

      reset({
        ...currentPrefix,
        assetSubcategories: checkSubcategories(currentPrefix.assetSubcategories)
      });
    }
  }, [currentPrefix]);

  useEffect(() => {
    if (id) {
      getPrefixAction(id);
    }
  }, [id]);

  useEffect(() => {
    getCreationFormAction();
  }, []);

  useEffect(() => {
    if (id && currentPrefix?.id) {
      const { id, prefix, assetCategory } = currentPrefix;
      breadcrumbs.current = [
        {
          path: `${CATEGORY_DETAILED_PATH}/${assetCategory.id}`,
          name: assetCategory.name
        },
        {
          path: `${PREFIX_DETAILED_PATH}/${id}`,
          name: prefix
        },
        { name: 'Edit prefix' }
      ];
    }
  }, [currentPrefix]);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const getBack = () =>
    activeCategory?.id && !id
      ? history.push(`${CATEGORY_DETAILED_PATH}/${activeCategory.id}`)
      : currentPrefix?.id
      ? history.push(`${PREFIX_DETAILED_PATH}/${currentPrefix.id}`)
      : history.push(CATEGORY_LIST_PATH);

  const handleErrors = (err) =>
    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));

  const agreeModal = () => {
    const getUpdatedFields = () => getValues('assetFields').filter(({ deleted }) => !deleted);
    const getUpdatedSubcategories = () => {
      if (
        getValues('assetSubcategories')?.length === 1 &&
        !getValues('assetSubcategories[0]')?.id
      ) {
        return [];
      }
      return getValues('assetSubcategories');
    };

    const getUpdatedValues = () => ({
      ...getValues(),
      assetFields: getUpdatedFields(),
      assetSubcategories: getUpdatedSubcategories()
    });

    switch (modalData.type) {
      case NEW_PREFIX_TYPE: {
        createPrefixAction(getUpdatedValues()).then((err) => err?.errors && handleErrors(err));
        closeModal();
        break;
      }
      case UPDATE_PREFIX_TYPE: {
        updatePrefixAction(getUpdatedValues()).then((err) => err?.errors && handleErrors(err));
        closeModal();
        break;
      }
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(modalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  const closeModal = () => setModalData({ isOpened: false });

  const handleCancelClick = () => {
    if (isDirty) {
      setModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      getBack();
    }
  };

  const validationRules = [
    { name: 'name', inputType: 'text', errorMessage: 'Name field is required' },
    { name: 'prefix', inputType: 'text', errorMessage: 'Prefix field is required' },
    { name: 'description', inputType: 'text', errorMessage: 'Description field is required' },
    { name: 'prefixType', inputType: 'object', errorMessage: 'Type field is required' },
    { name: 'assetCategory', inputType: 'object', errorMessage: 'Category field is required' }
  ];
  const validateForm = () => {
    let isFormValid = true;
    validationRules.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues(name), inputType)) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    if (id) {
      const fields = getValues('assetFields') || [];
      const filtered = fields.filter(({ deleted }) => !deleted);
      if (!filtered.length) {
        setError('assetFields', getErrorsProperties('Fields field is required'), {
          shouldFocus: true
        });
        isFormValid = false;
      }
    } else {
      if (isParameterInvalid(getValues('assetFields'), 'array')) {
        setError('assetFields', getErrorsProperties('Fields field is required'), {
          shouldFocus: true
        });
        isFormValid = false;
      }
    }
    return isFormValid;
  };

  const handleCreateClick = () => {
    if (!validateForm()) return;

    setModalData(id ? UPDATE_PREFIX : CREATE_NEW_PREFIX);
  };

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  return (
    <section className={styles.wrapper}>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <div className={styles.header}>
        {!isMobile && (
          <BreadcrumbsNav
            itemsArray={breadcrumbs.current}
            setDialogModalData={setModalData}
            formIsChanged={isDirty}
          />
        )}
        <div className={styles.header__block}>
          <h1>{id ? 'Edit prefix' : 'Add new prefix'}</h1>
          {isMobile && <BackButton onCancel={handleCancelClick} />}
        </div>
      </div>
      {children}
      <div className={styles.footer}>
        <MainButton text="cancel" type="secondary" action={handleCancelClick} />
        <MainButton
          text={id ? 'save' : 'create'}
          type="primary"
          action={handleCreateClick}
          isDisabled={isFormInvalid()}
        />
      </div>
    </section>
  );
}
