import React, { useState } from 'react';
import styles from './AssetPrefixes.module.scss';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { Paper, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { FormSearchInput } from 'components/FormComponents';
import ErrorBlock from 'components/ErrorBlock';

import clsx from 'clsx';

import { ASSETS_TABLE_ERROR_MESSAGE } from '../../helpers';

const FIELD_ARRAY_NAME = 'assetPrefixes';

export function AssetPrefixes() {
  const { control, setValue, getValues, formState } = useFormContext();
  const { errors } = formState;
  const { fields } = useFieldArray({
    control,
    name: FIELD_ARRAY_NAME
  });

  const [renderCount, setRenderCount] = useState(0);

  const isUniqueCell = (index) =>
    !(index !== 0 && fields[index]?.['assetPrefix']?.id === fields[index - 1]?.['assetPrefix']?.id);

  const getRowSpan = (index) =>
    fields.filter(
      ({ assetPrefix }, i, array) => assetPrefix?.id === array[index]?.['assetPrefix']?.id
    )?.length;

  const getSearchOptions = (index) => {
    const { assets, selectedAsset, assetPrefix } = getValues(FIELD_ARRAY_NAME)[index];

    if (!assets.length) return [];

    const selectedPrefixAssetIds = getValues(FIELD_ARRAY_NAME)
      .filter((el) => el.assetPrefix.id === assetPrefix.id)
      .map(({ selectedAsset }) => selectedAsset?.id)
      .filter(Boolean);

    const excludedAssetIds = selectedPrefixAssetIds?.filter((id) => id !== selectedAsset?.id) || [];

    return assets.filter(({ id }) => !excludedAssetIds.includes(id));
  };

  const handleSelect = (name, value) => {
    setValue(name, value);
    setRenderCount(renderCount + 1);
  };

  return (
    <div className={styles.assets}>
      <TableContainer className={styles.table} component={Paper}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Prefix</StyledTableCell>
              <StyledTableCell>Required QTY</StyledTableCell>
              <StyledTableCell>Assets</StyledTableCell>
              <StyledTableCell>Point of origin (Sublocation)</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {fields.map(({ id, assetPrefix, requiredQuantity }, index) => (
              <StyledTableRow key={id}>
                {isUniqueCell(index) && (
                  <StyledTableCell rowSpan={getRowSpan(index)} className={styles.prefixCell}>
                    {assetPrefix.prefix}
                  </StyledTableCell>
                )}
                {isUniqueCell(index) && (
                  <StyledTableCell rowSpan={getRowSpan(index)} className={styles.qtyCell}>
                    {requiredQuantity}
                  </StyledTableCell>
                )}
                <StyledTableCell
                  className={clsx(
                    styles.assetCell,
                    errors?.assetPrefixes?.[index]?.selectedAsset?.message && styles.invalid
                  )}>
                  <FormSearchInput
                    name={`${FIELD_ARRAY_NAME}[${index}].selectedAsset`}
                    options={getSearchOptions(index)}
                    optionType="assetPrefixTypeWithLocation"
                    onSelect={handleSelect}
                    clearable
                    classes={styles.asset_search}
                    disableError
                    hideErrorMessage
                  />
                </StyledTableCell>
                <StyledTableCell className={styles.originCell}>
                  <Controller
                    control={control}
                    name={`${FIELD_ARRAY_NAME}[${index}].selectedAsset`}
                    render={({ field: { value } }) => (
                      <>
                        {value?.location?.siteCode}
                        {value?.sublocation?.name && ` (${value?.sublocation?.name})`}
                      </>
                    )}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!errors?.assetPrefixes && <ErrorBlock errorText={ASSETS_TABLE_ERROR_MESSAGE} />}
    </div>
  );
}
