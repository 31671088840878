import { useCallback, useEffect, useRef, useState } from 'react';

import useWebSocket from 'react-use-websocket';

import { REPORT_HAS_BEEN_CLOSED } from 'constants/dialogPopupsData';

import { getToken, SOCKET_API_BASE_ADDRESS } from 'api/api';

import { extractIndexFromSignatureName, getLastSavedValue, MESSAGE_TYPES } from './helpers';

const useDataSync = ({
  getValues,
  setValue,
  clearErrors,
  onSave,
  setModalData,
  socketEnabled,
  setSocketEnabled,
  addSignature,
  removeSignature
}) => {
  const editedField = useRef({});
  const lastSavedReport = useRef({});

  const [sessionId, setSessionId] = useState(null);
  const [connectedUsers, setConnectedUsers] = useState([]);

  const { lastJsonMessage, sendJsonMessage } = useWebSocket(
    `${SOCKET_API_BASE_ADDRESS}/generalReport`,
    {
      queryParams: {
        entityId: getValues('id'),
        tokenHeader: `Bearer ${getToken()}`
      },
      onOpen: () => console.log('WebSocket connection opened!'),
      onClose: () => console.log('WebSocket connection closed!'),
      onError: (event) => console.error('WebSocket error:', event),
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 3000
    },
    socketEnabled
  );

  const handleSignatureUpdate = useCallback(
    (name, value) => {
      if (!('id' in value)) return;

      if (value.id) {
        addSignature({ id: value.id, name: '', position: '', additionalInfo: '', resource: null });
      } else {
        const index = extractIndexFromSignatureName(name);
        removeSignature(index);
      }
    },
    [addSignature, removeSignature]
  );

  const handleClearErrors = useCallback(
    (fieldValues = []) => {
      const names = fieldValues.map(({ name = '' }) => {
        if (name.includes('options') && name.endsWith('.selected'))
          // to generate name from generalReportFields[2].data.options[0].selected to generalReportFields[2].data.options
          return name.slice(0, name.lastIndexOf('['));
        return name;
      });

      clearErrors(names);
    },
    [clearErrors]
  );

  const handleFocusField = useCallback(
    (e) => {
      const fieldName = e.target.name;
      editedField.current.name = fieldName;
      editedField.current.value = getValues(fieldName) || '';
    },
    [getValues]
  );

  const handleBlurField = useCallback(
    (e) => {
      const fieldName = e.target.name;
      const oldValue = editedField.current.value;
      const newValue = getValues(fieldName) || '';

      editedField.current = {};

      if (oldValue === newValue) {
        if (!lastSavedReport.current?.id) return;

        const lastSavedValue = getLastSavedValue(fieldName, lastSavedReport.current || {}) || '';

        if (newValue === lastSavedValue) return;

        setValue(fieldName, lastSavedValue);
        return;
      }

      onSave();
      lastSavedReport.current = getValues();
    },
    [getValues, onSave, lastJsonMessage]
  );

  useEffect(() => {
    if (!lastJsonMessage) return;

    function handleModalMessage(message) {
      setModalData({ ...REPORT_HAS_BEEN_CLOSED, title: message, isOpened: true });
      setSocketEnabled(false);
    }

    function handleUpdateMessage(data, updatedFieldValues) {
      lastSavedReport.current = data;

      updatedFieldValues?.forEach(({ name, value }) => {
        if (name === editedField.current.name) return;

        if (name.startsWith('signatures[') && name.endsWith(']')) {
          handleSignatureUpdate(name, value);
          return;
        }

        setValue(name, value);
      });

      handleClearErrors(updatedFieldValues);
    }

    const { data, message, responseType, updatedFieldValues, users } = lastJsonMessage;

    const handlers = {
      [MESSAGE_TYPES.PING]: () => sendJsonMessage({ responseType: MESSAGE_TYPES.PONG }),
      [MESSAGE_TYPES.USERS]: () => setConnectedUsers(users || []),
      [MESSAGE_TYPES.CONNECT]: () => setSessionId(message),
      [MESSAGE_TYPES.DELETE]: () => handleModalMessage(message),
      [MESSAGE_TYPES.SUBMIT]: () => handleModalMessage(message),
      [MESSAGE_TYPES.UPDATE]: () => handleUpdateMessage(data, updatedFieldValues)
    };

    const handler = handlers[responseType];
    if (handler) handler();
  }, [lastJsonMessage]);

  return { sessionId, connectedUsers, onFocus: handleFocusField, onBlur: handleBlurField };
};

export default useDataSync;
