import {
  CLEAR_BILL_OF_LADING_STATE,
  SET_BILL_OF_LADING_CREATION_FORM,
  SET_BILL_OF_LADING_FILTER,
  SET_BILL_OF_LADING_FILTER_CRITERIA,
  SET_CURRENT_BILL_OF_LADING
} from 'constants/reduceConstants';

const initialState = {
  currentBoL: {},
  filter: {},
  filterCriteria: {},
  creationForm: { itemsForAdding: [] }
};

const billOfLadingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_BILL_OF_LADING:
      return { ...state, currentBoL: payload };
    case SET_BILL_OF_LADING_FILTER:
      return { ...state, filter: payload };
    case SET_BILL_OF_LADING_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    case SET_BILL_OF_LADING_CREATION_FORM:
      return { ...state, creationForm: payload };
    case CLEAR_BILL_OF_LADING_STATE:
      return { ...initialState, filter: state.filter };
    default:
      return state;
  }
};

export default billOfLadingReducer;
