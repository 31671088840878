import { createSelector } from 'reselect';

const data = (state) => state.assembleBOMData;

// Summary
export const selectFilterData = () => createSelector([data], (state) => state.filter);

export const selectFilterCriteriaData = () =>
  createSelector([data], (state) => state.filterCriteria);

// Details
export const selectSingleAssembleData = () =>
  createSelector([data], (state) => state.currentAssemble);

export const selectStatusListFilterData = () =>
  createSelector([data], (state) => state.statusListFilter);

export const selectLocationListData = () => createSelector([data], (state) => state.locationList);

// Create Screen
export const selectCreationFormData = () => createSelector([data], (state) => state.creationForm);
