import { handleIdsFilter, handleNamesFilter } from 'helpers/ChipGenerator';
import { cloneObj } from 'helpers/AppHelpers';
import { ASSEMBLE_LIST_TABLE_PARAMETER } from 'constants/configTableConstants';

const defaultFilter = {
  filters: {
    searchQuery: '',
    locationIds: [],
    locationJobNumberIds: [],
    statusNames: [],
    looseItemConfigurationIds: []
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const generateInitialQuery = ({ getTableLimit, isAllLimitEnabled, filter }) => {
  const defaultPage = defaultFilter.pagination.page;
  const limit = getTableLimit(ASSEMBLE_LIST_TABLE_PARAMETER);

  const isAll = isAllLimitEnabled(ASSEMBLE_LIST_TABLE_PARAMETER);
  const page = limit === 100 && isAll ? defaultPage : filter?.pagination?.page || defaultPage;

  return {
    filters: { ...defaultFilter.filters, ...filter?.filters },
    pagination: { limit, page },
    sortRules: filter?.sortRules || defaultFilter.sortRules,
    responseReceived: false
  };
};

const generateChips = (criteria, filters = {}) => {
  const { locations, statuses, configurations } = criteria;

  const { statusNames } = filters;

  let newChips = [];

  const filterConfigs = [
    { options: locations, getLabel: (el) => el.siteCode, key: 'locationIds' },
    { options: locations, getLabel: (el) => el.locationJobNumber, key: 'locationJobNumberIds' },
    { options: configurations, getLabel: (el) => el.name, key: 'looseItemConfigurationIds' }
  ];

  filterConfigs.forEach(({ options, getLabel, key }) => {
    if (filters?.[key]?.length) {
      newChips = newChips.concat(handleIdsFilter(filters[key], options, getLabel, key));
    }
  });

  if (statusNames?.length) {
    newChips = newChips.concat(handleNamesFilter(statusNames, statuses, 'statusNames'));
  }

  return newChips.filter(Boolean);
};

const removeChip = (filters, deletedChip) => {
  const newFilters = cloneObj(filters);
  const { key, itemId, itemName } = deletedChip;

  switch (key) {
    case 'statusNames':
      newFilters[key] = newFilters[key].filter((name) => name !== itemName);
      break;
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }

  return newFilters;
};

const parseQuery = (query) => {
  const payload = {
    filters: { searchQuery: '' },
    pagination: { page: query.pagination.page, limit: query.pagination.limit }
  };

  const setFilters = (key, value) => {
    if (value?.length || value) {
      payload.filters[key] = value;
    }
  };

  const filterKeys = Object.keys(defaultFilter.filters);

  filterKeys.forEach((key) => setFilters(key, query.filters[key]));

  if (query.sortRules) {
    payload.sortRules = query.sortRules;
  }

  return payload;
};

const tableConfig = [
  { id: 1, label: 'Assembly Name', isSortable: true, parameter: 'name' },
  { id: 2, label: 'Configuration', isSortable: false },
  { id: 3, label: 'Location', isSortable: false },
  { id: 4, label: 'Status', isSortable: false },
  { id: 5, label: 'Created', isSortable: false },
  { id: 6, label: 'Creation by', isSortable: false }
];

export { defaultFilter, generateInitialQuery, generateChips, removeChip, parseQuery, tableConfig };
