export const isMoveQtyValid = (quantity, moveQty) => {
  return +moveQty <= +quantity;
};

export const isBaaQtyValid = (quantity, baaQty) => {
  return +baaQty <= +quantity;
};

export const isDestinationValid = (
  currentLocation,
  currentSublocation,
  currentAsset,
  destinationLocation,
  destinationSublocation,
  destinationAsset
) => {
  const currentIds = [currentLocation?.id, currentSublocation?.id, currentAsset?.id];
  const destinationIds = [
    destinationLocation?.id,
    destinationSublocation?.id,
    destinationAsset?.id
  ];

  const areAddressesIdentical = currentIds.every(
    (currentId, index) => currentId === destinationIds[index]
  );

  return !areAddressesIdentical;
};

export const isBaaQtyMoreThanMove = (baa, move) => {
  return baa > move;
};

export const isDestinationEmpty = (dest) => {
  return !dest?.id;
};

export const shouldItemsBeMoved = (move, moveBaa, quantity, baa) => {
  return +move - +moveBaa > +quantity - +baa;
};

export const areQuantitiesInvalid = (move, baa) => {
  return move === 0 && baa === 0;
};

export const isSumValid = (quantity, moveQty, baaQty) => {
  return +moveQty + +baaQty <= quantity;
};

export const isMoveQtyEmpty = (moveQty) => {
  return !moveQty;
};
