import {
  BAA_EXCEEDS_AVAILABLE_BAA_ERROR,
  BAA_EXCEEDS_SELECTED_ERROR,
  getLocationSelectedBaaErrorName,
  getLocationSelectedQuantityErrorName,
  SELECTED_EXCEEDS_AVAILABLE_ERROR,
  setLooseItemSectionId,
  getLooseItemSectionErrorName,
  ERROR_RESET_TYPES
} from '../../helpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';

const useValidation = ({ errors, fields, methods }) => {
  const { getValues, setError, clearErrors } = methods;

  const getRowId = (i) => fields?.[i]?.id;
  const isSelectedQuantityInvalid = (rowId) => !!errors?.looseItems?.[rowId]?.selectedQuantity;
  const isSelectedBaaQuantityInvalid = (rowId) =>
    !!errors?.looseItems?.[rowId]?.selectedByAheadAccount;
  const isLooseItemSectionInvalid = (id) =>
    !!errors?.looseItemIds?.[setLooseItemSectionId(id)]?.message;

  const getCellHighlightState = (looseItemId, index) => {
    const rowId = getRowId(index);
    const isSectionInvalid = isLooseItemSectionInvalid(looseItemId);
    const isRowInvalid = isSelectedQuantityInvalid(rowId) || isSelectedBaaQuantityInvalid(rowId);

    if (isSectionInvalid) {
      return { highlightSection: true, highlightRow: false };
    }

    if (isRowInvalid) {
      return { highlightSection: false, highlightRow: true };
    }

    return { highlightSection: false, highlightRow: false };
  };

  const clearSectionError = (index) => {
    const looseItemId = fields[index].looseItem.id;
    if (isLooseItemSectionInvalid(looseItemId)) {
      clearErrors(getLooseItemSectionErrorName(looseItemId));
    }
  };
  const clearSelectedQuantityError = (index) => {
    const rowId = getRowId(index);
    if (isSelectedQuantityInvalid(rowId)) {
      clearErrors(getLocationSelectedQuantityErrorName(rowId));
    }
  };
  const clearSelectedByAheadAccountError = (index) => {
    const rowId = getRowId(index);
    if (isSelectedBaaQuantityInvalid(rowId)) {
      clearErrors(getLocationSelectedBaaErrorName(rowId));
    }
  };

  const clearRowErrors = (type, index) => {
    switch (type) {
      case ERROR_RESET_TYPES.CHANGE_LOCATION: {
        clearSectionError(index);
        clearSelectedQuantityError(index);
        clearSelectedByAheadAccountError(index);
        break;
      }
      case ERROR_RESET_TYPES.REMOVE_TABLE_ROW: {
        clearSectionError(index);
        clearSelectedQuantityError(index);
        clearSelectedByAheadAccountError(index);
        break;
      }
      default:
        clearSectionError(index);
        break;
    }
  };

  const checkBaaQuantity = (_, selectedBaa = 0, index) => {
    const { id, byAheadAccount = 0 } = fields[index];
    const selectedQuantity = getValues(`looseItems[${index}].selectedQuantity`);

    const errorName = getLocationSelectedBaaErrorName(id);
    const hasError = isSelectedBaaQuantityInvalid(id);

    if (selectedBaa > selectedQuantity) {
      setError(errorName, getErrorsProperties(BAA_EXCEEDS_SELECTED_ERROR));
    } else if (selectedBaa > byAheadAccount) {
      setError(errorName, getErrorsProperties(BAA_EXCEEDS_AVAILABLE_BAA_ERROR));
    } else {
      if (hasError) {
        clearErrors(errorName);
      }
    }

    clearRowErrors(ERROR_RESET_TYPES.CHANGE_SELECTED_BAA, index);
  };

  const checkSelectedQuantity = (_, selectedQty = 0, index) => {
    const { id, quantity = 0 } = fields[index];

    const errorName = getLocationSelectedQuantityErrorName(id);
    const hasError = isSelectedQuantityInvalid(id);

    if (selectedQty > quantity) {
      if (!hasError) {
        setError(errorName, getErrorsProperties(SELECTED_EXCEEDS_AVAILABLE_ERROR));
      }
    } else {
      if (hasError) {
        clearErrors(errorName);
      }
    }

    const selectedByAheadAccount = getValues(`looseItems[${index}].selectedByAheadAccount`);
    checkBaaQuantity('', selectedByAheadAccount, index);
    clearRowErrors(ERROR_RESET_TYPES.CHANGE_SELECTED_QUANTITY, index);
  };

  return {
    checkBaaQuantity,
    checkSelectedQuantity,
    getCellHighlightState,
    clearRowErrors
  };
};

export default useValidation;
