import api from '../api';
import { queryToString } from '../../helpers/AppHelpers';

// Summary
export const getProjectsApi = (q) =>
  api.get(`/Projects?${queryToString(q)}`).then((res) => res.data);

export const getLocationsApi = (query) =>
  api.get(`/Locations/Shorted?${queryToString(query)}`).then((res) => res.data);

// Details
export const deleteProjectApi = (id) => api.delete(`/Project?id=${id}`).then((res) => res.data);

export const updateProjectLocationsApi = (params) =>
  api.put(`/Locations`, params).then((res) => res);

// Edit
export const getStatesListApi = () => api.get('/UnitedStates').then((res) => res.data);

export const createProjectApi = (params) => api.post(`/Project`, params).then((res) => res.data);

export const updateProjectApi = (params) => api.put(`/Project`, params).then((res) => res.data);

// Common
export const getSingleProjectApi = (id) => api.get(`/Project?id=${id}`).then((res) => res.data);
