import { useDispatch } from 'react-redux';

import {
  getBillOfLadings,
  getFilterCriteria,
  setFilter,
  clearState
} from 'actions/billOfLadingActions';

export function useBillOfLadingActions() {
  const dispatch = useDispatch();

  const getBillOfLadingsAction = (q) => dispatch(getBillOfLadings(q));

  const setFilterAction = (data) => dispatch(setFilter(data));
  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());

  const clearStateAction = () => dispatch(clearState());

  return {
    getBillOfLadingsAction,
    setFilterAction,
    getFilterCriteriaAction,
    clearStateAction
  };
}
