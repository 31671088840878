import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FormLabel, FormRow } from 'components/FormComponents';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { FormProvider, useForm } from 'react-hook-form';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useProjectSelector } from 'hooks/Project';

import { sortByParam } from 'helpers/AppHelpers';

import { defaultFilter } from '../helpers';

export function Filter({ open, setOpen, onApply }) {
  const isMobile = useMobileViewport();

  const methods = useForm({
    defaultValues: { ...defaultFilter },
    mode: 'onChange'
  });
  const { getValues, reset } = methods;

  const { filter, filterCriteria } = useProjectSelector();
  const { locations } = filterCriteria;

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = { ...defaultFilter.filters, searchQuery: getValues('searchQuery') };
    reset(defaultState);
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <FormRow>
                <FormLabel>Location</FormLabel>
                <FormAutoMultiSelect
                  name="locationIds"
                  menuItems={locations || []}
                  options={{ label: 'siteCode', disableByObjectTracker: true, disableLabel: true }}
                />
              </FormRow>
            </div>
            <div className={styles.form__block}>
              <FormRow>
                <FormLabel>Job Number</FormLabel>
                <FormAutoMultiSelect
                  name="locationJobNumberIds"
                  menuItems={sortByParam(locations, 'locationJobNumber') || []}
                  options={{
                    label: 'locationJobNumber',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
              <FormRow type="checkbox">
                <FormLabel>Show inactive?</FormLabel>
                <FormCheckbox name="active" reversed />
              </FormRow>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
