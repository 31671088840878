import React, { useEffect, useState } from 'react';
import styles from './Wrapper.module.scss';

import Header from './Header';
import Footer from './Footer';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import {
  useCreateAssetsBatchActions,
  useCreateAssetsBatchSelector
} from 'hooks/CreateBatchOfAssets';

import { focusFieldByError, getErrorsProperties } from 'helpers/ErrorValidator';
import { getValidatedFields } from '../../helpers';

import { CATEGORY_LIST_PATH } from 'constants/routeConstants';
import { EXIT_WITHOUT_SAVING } from 'constants/dialogPopupsData';

export default function CreateAssetBatchWrapper({ children }) {
  const history = useHistory();
  const [modalData, setModalData] = useState({});

  const { unsavedFormData } = useCreateAssetsBatchSelector();
  const { setUnsavedFormDataAction, createAssetsAction, toggleScreenAction } =
    useCreateAssetsBatchActions();

  const { getValues, setError, formState } = useFormContext();
  const { isDirty, errors } = formState;

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    const errorFields = [
      {
        error: errors?.lastLocationHistory?.location,
        selector: `input[id="lastLocationHistory.location"]`
      },
      { error: errors?.rental?.rentalStatus, selector: `input[name="rental.rentalStatus"]` },
      { error: errors?.qty, selector: `input[name="qty"]` }
    ];

    for (const { error, selector } of errorFields) {
      focusFieldByError(error, selector);
      if (error) break;
    }
  }, [errors?.lastLocationHistory?.location, errors?.rental?.rentalStatus, errors?.qty]);

  const validateForm = () => {
    let isFormValid = true;

    const validatedFields = getValidatedFields(getValues());

    if (validatedFields.length) {
      validatedFields.forEach(({ name, errorMessage }) => {
        setError(name, getErrorsProperties(errorMessage));
        isFormValid = false;
      });
    }
    return isFormValid;
  };

  const handleBackClick = () => {
    if (isDirty || unsavedFormData) {
      setModalData(EXIT_WITHOUT_SAVING);
    } else {
      goBack();
    }
  };

  const handleCreateClick = () => {
    if (!validateForm()) return;

    createAssetsAction(getValues()).then((res) => {
      if ([200, 201].includes(res.status)) {
        toggleScreenAction();
      }
    });
  };

  const goBack = () => history.push(CATEGORY_LIST_PATH);

  const agreeModal = () => goBack();
  const closeModal = () => setModalData({});

  return (
    <div className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <Header onLinkClick={handleBackClick} />
      {children}
      <Footer onCreate={handleCreateClick} onCancel={handleBackClick} />
    </div>
  );
}
