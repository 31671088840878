import { useSelector } from 'react-redux';
import {
  selectFilterData,
  selectFilterCriteriaData
} from 'pages/PicklistAndLeavelist/BillOfLading/selectors';

export function useBillOfLadingSelector() {
  const filter = useSelector(selectFilterData());
  const filterCriteria = useSelector(selectFilterCriteriaData());

  return {
    filter,
    filterCriteria
  };
}
