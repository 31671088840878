import React from 'react';
import AssetTablesToggle from './Tables/AssetTablesToggle';

import { useMobileViewport } from 'hooks/useMobileViewport';

import { ShowPopupButton } from 'components/Buttons';
import TablesModal from './Modal/TablesModal';

export default function AssetTables() {
  const isMobile = useMobileViewport();

  return !isMobile ? (
    <AssetTablesToggle />
  ) : (
    <ShowPopupButton
      label="Additional Info"
      PopupComponent={(open, setOpen) => <TablesModal open={open} setOpen={setOpen} />}
    />
  );
}
