import React from 'react';
import styles from './components.module.scss';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import AccordionWrapper from '../../commonComponents/AccordionWrapper';
import { destinationFormConfig } from './formConfigs';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectCreationFormData } from '../../selectors';
import { getAddress } from 'helpers/AppHelpers';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import { destinationFieldNames, DRAT_COMPANY_NAME } from '../helpers';

function DestinationForm() {
  const { control, formState, setValue, clearErrors } = useFormContext();
  const { errors } = formState;

  const creationForm = useSelector(selectCreationFormData());
  const { destinationLocations } = creationForm;

  const isFormInvalid =
    errors?.destinationCompanyName?.message ||
    errors?.destinationAddress?.message ||
    errors?.destinationCity?.message ||
    errors?.destinationZIP?.message ||
    errors?.destinationState?.message;

  const selectLocation = (name, value) => {
    if (!value) return;

    setValue(name, value);

    const { siteCode, address1, address2, city, zip, state } = value;

    setValue('destinationLocation', siteCode === 'Other' ? '' : siteCode || '');
    setValue('destinationAddress', getAddress(address1, address2));
    setValue('destinationAddress2', '');
    setValue('destinationCity', city || '');
    setValue('destinationZIP', zip || '');
    setValue('destinationState', state?.name || '');
    setValue('destinationCompanyName', siteCode === 'Other' ? '' : DRAT_COMPANY_NAME);
    setValue('destinationPointOfContact', '');
    setValue('destinationPOCPhone', '');

    destinationFieldNames.forEach((name) => clearErrors(name));
  };

  return (
    <AccordionWrapper title="Destination" isFormInvalid={isFormInvalid}>
      <div className={styles.location}>
        <div className={styles.location__block}>
          <p className={styles.location__label}>Destination</p>
          <div className={styles.location__block_row}>
            <label>Destination Location</label>
            <FormSearchInput
              name="destinationLocationItem"
              options={destinationLocations || []}
              onSelect={selectLocation}
            />
          </div>
          <Controller
            control={control}
            name="destinationLocationItem"
            render={({ field: { value } }) =>
              value?.siteCode === 'Other' ? (
                <div className={styles.location__block_row}>
                  <label>Custom Destination</label>
                  <FormInputText name="destinationLocation" options={{ max: 500 }} />
                </div>
              ) : null
            }
          />
          {destinationFormConfig.map((row) => (
            <div key={row.id} className={styles.location__block_row}>
              <label>{row.label}</label>
              <FormInputText name={row.param} options={{ max: row.maxLength, rule: row.rule }} />
            </div>
          ))}
        </div>
      </div>
    </AccordionWrapper>
  );
}

export default DestinationForm;
