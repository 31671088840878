import { createSelector } from 'reselect';

const locationsData = (state) => state.locationsData;

export const selectFilterData = () => createSelector([locationsData], (state) => state.filter);

export const selectFilterCriteriaData = () =>
  createSelector([locationsData], (state) => state.filterCriteria);

export const selectSingleLocationData = () =>
  createSelector([locationsData], (state) => state.currentLocation);

export const selectSelectedLocationDetailsTabData = () =>
  createSelector([locationsData], (state) => state.selectedTab);

export const selectSiteTypesListData = () =>
  createSelector([locationsData], (state) => state.siteTypesList);

export const selectStatesListData = () =>
  createSelector([locationsData], (state) => state.statesList);

export const selectProjectsListData = () =>
  createSelector([locationsData], (state) => state.projectFilterList);

export const selectActiveProject = () =>
  createSelector([locationsData], (state) => state.activeProject);

export const selectAssetRentalStatuses = () =>
  createSelector([locationsData], (state) => state.assetRentalStatuses);

// location tables
export const selectLocationLooseItems = () =>
  createSelector([locationsData], (state) => state.locationLooseItems);
export const selectLocationLooseItemsFilter = () =>
  createSelector([locationsData], (state) => state.looseItemsFilter);

export const selectServiceTicketsListData = () =>
  createSelector([locationsData], (state) => state.openedTicketsList);
export const selectOpenedTicketsListFilterData = () =>
  createSelector([locationsData], (state) => state.openedTicketsListFilter);

export const selectClosedServiceTicketsListData = () =>
  createSelector([locationsData], (state) => state.closedTicketsList);
export const selectClosedTicketsListFilterData = () =>
  createSelector([locationsData], (state) => state.closedTicketsListFilter);

export const selectAssetsListData = () =>
  createSelector([locationsData], (state) => state.assetsList);
export const selectAssetsListFilterData = () =>
  createSelector([locationsData], (state) => state.assetsListFilter);
