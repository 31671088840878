import React, { useState } from 'react';
import styles from '../ItemsTableBody.module.scss';
import { Button, TextField } from '@material-ui/core';
import { StyledTableCell } from 'components/StyledComponents';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { reformatNumberValue } from 'helpers/AppHelpers';
import clsx from 'clsx';

function QuantityCell({ index, value, updateTable, name, isInvalid, isWarning, errorText }) {
  const [values, setValues] = useState({});

  const handleOpen = () => {
    setValues({ open: true, count: value });
  };

  const handleClick = (param) => {
    if (param === 'accept') {
      updateTable(name, index, values.count);
    }
    setValues({});
  };

  const onInput = (event) => {
    const inputValue = reformatNumberValue(event.target.value);
    setValues({ ...values, count: inputValue });
  };

  const handleFocus = (event) => event.target.select();

  return (
    <StyledTableCell
      align="left"
      className={clsx(
        styles.quantityCell,
        !!isInvalid && styles.error,
        !!isWarning && styles.warning
      )}>
      {!values.open ? (
        <section className={styles.quantityBlock} onClick={handleOpen}>
          {value}
        </section>
      ) : (
        <section className={styles.quantityBlock}>
          <TextField
            name={name}
            className={styles.textInput}
            variant="outlined"
            value={values.count}
            onChange={onInput}
            onFocus={handleFocus}
          />
          <div className={styles.helpers}>
            <Button onClick={handleClick} className={styles.alert}>
              <CloseIcon />
            </Button>
            <Button onClick={() => handleClick('accept')} className={styles.success}>
              <CheckIcon />
            </Button>
          </div>
        </section>
      )}
      {!!errorText && (
        <div className={styles.errorBlock}>
          <span>{errorText}</span>
        </div>
      )}
    </StyledTableCell>
  );
}

export default QuantityCell;
