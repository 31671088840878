import React, { useState } from 'react';
import { Checkbox, Paper, Table, TableContainer, TableHead, TableRow } from '@material-ui/core';
import styles from './LooseItemsTable.module.scss';
import { StyledTableCell } from 'components/StyledComponents';
import { emptyValue } from 'helpers/AppHelpers';
import ItemsTableBody from './ItemsTableBody/ItemsTableBody';
import TableEditor from './TableEditor/TableEditor';
import {
  isBaaQtyMoreThanMove,
  isBaaQtyValid,
  isDestinationValid,
  isMoveQtyValid
} from '../helpers';
import { useFormContext } from 'react-hook-form';

function LooseItemsTable({
  isMobile,
  tableData,
  setTableData,
  locations,
  itemsLocations,
  validationErrors,
  warnings,
  setWarnings,
  setValidationErrors,
  handleItemClick
}) {
  const { setValue } = useFormContext();
  const [checked, setChecked] = useState([]);

  const updateTable = (name, index, value) => {
    let newArray = [...tableData];
    const rowId = newArray[index].rowId;

    const validateWarnings = () => {
      const warns = { ...warnings };
      warns[rowId] = '';
      setWarnings(warns);
    };

    const validateTable = (type) => {
      const errors = { ...validationErrors };
      switch (type) {
        case 'location':
          errors[rowId] = {
            destinationLocation: isDestinationValid(
              newArray[index]?.location,
              newArray[index]?.sublocation,
              newArray[index]?.containedInAsset,
              newArray[index]?.destinationLocation,
              newArray[index]?.destinationSublocation,
              newArray[index]?.destinationAsset
            )
              ? ''
              : 'The “Destination”, “Sublocation” and “Asset” values must be different from “Current Location(Sublocation)” value'
          };
          break;
        case 'destination':
          errors[rowId] = {
            ...errors[rowId],
            destinationLocation: isDestinationValid(
              newArray[index]?.location,
              newArray[index]?.sublocation,
              newArray[index]?.containedInAsset,
              newArray[index]?.destinationLocation,
              newArray[index]?.destinationSublocation,
              newArray[index]?.destinationAsset
            )
              ? ''
              : 'The “Destination”, “Sublocation” and “Asset” values must be different from “Current Location(Sublocation)” value'
          };
          break;
        case 'moveQuantity':
          errors[rowId] = {
            ...errors[rowId],
            moveQuantity: isMoveQtyValid(newArray[index].quantity, newArray[index].moveQuantity)
              ? ''
              : 'The “Move Total QTY” value must not exceed “Available QTY(BAA*)” value.',
            moveByAheadAccount: isBaaQtyMoreThanMove(
              newArray[index].moveByAheadAccount,
              newArray[index].moveQuantity
            )
              ? '“From BAA*” value must not exceed “Move Total QTY”'
              : ''
          };
          break;
        case 'moveByAheadAccount':
          errors[rowId] = {
            ...errors[rowId],
            moveByAheadAccount: !isBaaQtyValid(
              newArray[index]?.byAheadAccount || 0,
              newArray[index].moveByAheadAccount
            )
              ? 'The “From BAA* QTY” value must not exceed “Available QTY(BAA*)” value in the brackets.'
              : isBaaQtyMoreThanMove(
                  newArray[index].moveByAheadAccount,
                  newArray[index].moveQuantity
                )
              ? '“From BAA*” value must not exceed “Move Total QTY”'
              : ''
          };
          break;
        case 'remove':
        default:
          errors[rowId] = {};
          break;
      }
      setValidationErrors(errors);
    };

    if (name === 'remove') {
      newArray = newArray.filter((_, i) => i !== index);
      setValue(`rows[${rowId}]`, null);
      validateTable('remove');
    }
    if (name === 'location') {
      newArray[index]['fakeLocation'] = value;
      newArray[index]['location'] = value.location;
      newArray[index]['id'] = value.id;
      if (value?.sublocation?.id) {
        newArray[index]['sublocation'] = value.sublocation;
      } else {
        newArray[index]['sublocation'] = null;
      }
      if (value?.containedInAsset?.id) {
        newArray[index]['containedInAsset'] = value.containedInAsset;
      } else {
        newArray[index]['containedInAsset'] = null;
      }
      const item = itemsLocations.find(({ id }) => id === value.id);
      newArray[index].quantity = item.quantity;
      newArray[index].byAheadAccount = item?.byAheadAccount || 0;
      validateTable('location');
    }
    if (name === 'destinationLocation') {
      newArray[index][name] = value;
      newArray[index].destinationSublocation = null;
      newArray[index].destinationAsset = null;
      setValue(`rows[${rowId}]`, null);
      validateTable('destination');
    }
    if (name === 'destinationSublocation') {
      newArray[index][name] = value;
      newArray[index].destinationAsset = null;
      setValue(`rows[${rowId}]`, null);
      validateTable('destination');
    }
    if (name === 'destinationAsset') {
      newArray[index][name] = value;
      validateTable('destination');
      if (!value?.id) return;
      const { currentLocationId, currentSublocationId } = value;
      const location = locations?.find(({ id }) => id === currentLocationId) || {};
      const sublocation =
        location.sublocations?.find(({ id }) => id === currentSublocationId) || null;
      if (!location?.id) return;
      newArray[index].destinationLocation = location;
      newArray[index].destinationSublocation = sublocation;
    }
    if (name === 'moveQuantity') {
      newArray[index][name] = +value;
      validateTable('moveQuantity');
      validateWarnings();
    }
    if (name === 'moveByAheadAccount') {
      newArray[index][name] = +value;
      validateTable('moveByAheadAccount');
    }
    setTableData(newArray);
  };

  const handleAllCheckbox = () => {
    if (checked.length) {
      setChecked([]);
    } else {
      setChecked([...tableData.map(({ rowId }) => rowId)]);
    }
  };

  const onApply = (destination, quantity) => {
    const newArray = [...tableData].map((el) => {
      if (checked.includes(el.rowId)) {
        if (destination?.id) {
          setValue(`rows[${el.rowId}]`, null);
        }
        return {
          ...el,
          destinationLocation: destination?.id ? destination : el.destinationLocation,
          destinationSublocation: destination?.id
            ? null
            : el?.destinationSublocation?.id
            ? el.destinationSublocation
            : null,
          destinationAsset: destination?.id
            ? null
            : el?.destinationAsset?.id
            ? el.destinationAsset
            : null,
          moveQuantity: quantity ? quantity : el.moveQuantity
        };
      } else return el;
    });
    setTableData(newArray);
    const errors = { ...validationErrors };
    const warns = { ...warnings };
    checked.forEach((id) => {
      errors[id] = {};
      warns[id] = '';
    });
    setValidationErrors(errors);
    setWarnings(warns);
  };

  return (
    <>
      {!!checked.length && (
        <TableEditor isMobile={isMobile} locationList={locations} onApply={onApply} />
      )}
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {!!tableData?.length && (
                <StyledTableCell align="center" className={styles.checkboxHead}>
                  <Checkbox
                    classes={{ root: styles.checkbox, checked: styles.checked }}
                    onChange={handleAllCheckbox}
                    value={'selectAll'}
                    checked={checked.length === tableData.length}
                  />
                </StyledTableCell>
              )}
              <StyledTableCell>Loose Items</StyledTableCell>
              <StyledTableCell>Current Location (Sublocation) [Asset]</StyledTableCell>
              <StyledTableCell>
                Available QTY(BAA<span className={styles.footnote}>[1]</span>)
              </StyledTableCell>
              <StyledTableCell>Destination</StyledTableCell>
              <StyledTableCell>Sublocation</StyledTableCell>
              <StyledTableCell>Contained In</StyledTableCell>
              <StyledTableCell>Move Total QTY</StyledTableCell>
              <StyledTableCell>
                From BAA<span className={styles.footnote}>[1]</span>
              </StyledTableCell>
              <StyledTableCell>{emptyValue}</StyledTableCell>
            </TableRow>
          </TableHead>
          <ItemsTableBody
            tableData={tableData}
            locations={locations}
            itemsLocations={itemsLocations}
            updateTable={updateTable}
            checked={checked}
            setChecked={setChecked}
            validationErrors={validationErrors}
            warnings={warnings}
            handleItemClick={handleItemClick}
          />
        </Table>
      </TableContainer>
      {!isMobile && <label className={styles.hintLabel}>[1]Buy Ahead Account</label>}
    </>
  );
}

export default LooseItemsTable;
