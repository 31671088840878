import React from 'react';
import styles from './components.module.scss';
import blankIcon from 'assets/images/emptyBlank.svg';
import { fileDownload } from 'helpers/AppHelpers';
import { useDispatch } from 'react-redux';
import { getBlank } from 'actions/billOfLadingActions';

export default function DownloadBlankButton() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(getBlank()).then((file) => fileDownload(file));
  };

  return (
    <button className={styles.downloadButton} onClick={handleClick}>
      <img src={blankIcon} alt="" />
      Download Blank BOL
    </button>
  );
}
