const NO_ERROR = '';

const MOVE_QUANTITY_ERROR = 'The “Add QTY” value must not exceed “Available QTY(BAA*)” value';
const MOVE_QUANTITY_IS_EMPTY_ERROR = 'The “Add QTY” value is required';
const BUY_AHEAD_ACCOUNT_ERROR =
  'The “From BAA” value must not exceed “Available QTY(BAA)” value in the brackets';
const BUY_AHEAD_ACCOUNT_MORE_THAN_MOVE_ERROR = '“From BAA*” value must not exceed “Add QTY”';

export const checkMoveQuantity = ({ quantity = 0, moveQuantity = 0 }) => {
  if (!moveQuantity) return MOVE_QUANTITY_IS_EMPTY_ERROR;
  return +moveQuantity <= +quantity ? NO_ERROR : MOVE_QUANTITY_ERROR;
};

export const isBaaQtyMoreThanMove = (moveBaa = 0, moveQty = 0) => moveBaa > moveQty;

export const checkByAheadAccount = ({
  byAheadAccount = 0,
  moveByAheadAccount = 0,
  moveQuantity = 0
}) =>
  +moveByAheadAccount > +byAheadAccount
    ? BUY_AHEAD_ACCOUNT_ERROR
    : isBaaQtyMoreThanMove(moveByAheadAccount, moveQuantity)
    ? BUY_AHEAD_ACCOUNT_MORE_THAN_MOVE_ERROR
    : NO_ERROR;

export const checkRowForWarnings = ({
  moveQuantity = 0,
  moveByAheadAccount = 0,
  quantity = 0,
  byAheadAccount = 0
}) => +moveQuantity - +moveByAheadAccount > +quantity - +byAheadAccount;

export const tableHeadConfig = [
  { id: 1, label: 'Loose Items', isSortable: false },
  { id: 2, label: 'Current Location (Sublocation) [Asset]', isSortable: false },
  { id: 3, label: 'Available QTY(BAA)', footnote: '[1]', isSortable: false },
  { id: 4, label: 'Add QTY', isSortable: false },
  { id: 5, label: 'From BAA', footnote: '[1]', isSortable: false },
  { id: 6, label: '', isSortable: false }
];

export const getQuantityCellValue = ({ quantity, byAheadAccount }) =>
  quantity + '' + (byAheadAccount ? ' (' + byAheadAccount + ')' : '');
