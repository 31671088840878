import React, { useCallback } from 'react';
import styles from './LooseItems.module.scss';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { IconButton, Paper, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import Comment from 'components/Comment';
import { FormSearchInput } from 'components/FormComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import ErrorBlock from 'components/ErrorBlock';

import clsx from 'clsx';

import { DEFAULT_QUANTITIES, ERROR_RESET_TYPES, setLooseItemSectionId } from '../../helpers';
import useValidation from './useValidation';

const FIELD_ARRAY_NAME = 'looseItems';

export function LooseItems() {
  const methods = useFormContext();
  const { control, getValues, formState } = methods;
  const { errors } = formState;
  const { fields, update, remove, insert } = useFieldArray({
    control,
    name: FIELD_ARRAY_NAME
  });

  const { checkBaaQuantity, checkSelectedQuantity, getCellHighlightState, clearRowErrors } =
    useValidation({
      errors,
      fields,
      methods
    });

  const getLooseItemLocations = (looseItemId) => {
    const config = getValues('looseItemConfiguration');
    const configLooseItem = config?.[FIELD_ARRAY_NAME]?.find(({ id }) => id === looseItemId) || {};
    return configLooseItem?.locations || [];
  };
  const getSelectedLooseItemLocations = (looseItemId) =>
    getValues(FIELD_ARRAY_NAME)?.filter(({ looseItem }) => looseItem?.id === looseItemId) || [];

  const isUniqueCell = (index) =>
    !(index !== 0 && fields[index]?.['looseItem']?.id === fields[index - 1]?.['looseItem']?.id);

  const getRowSpan = (index) =>
    fields.filter(({ looseItem }, _, array) => looseItem?.id === array[index]?.['looseItem']?.id)
      ?.length;

  const getSearchOptions = useCallback(
    (index) => {
      const rowLooseItemId = fields?.[index]?.looseItem?.id || null;
      if (!rowLooseItemId) return [];

      const looseItemLocations = getLooseItemLocations(rowLooseItemId);
      if (!looseItemLocations?.length) return [];

      const rowSelectedLocationId = getValues(`${FIELD_ARRAY_NAME}[${index}].id`);
      const allSelectedLooseItemLocationIds = getValues(FIELD_ARRAY_NAME)
        .filter(({ looseItem }) => looseItem.id === rowLooseItemId)
        .map(({ id }) => id);

      const excludedLocationIds =
        allSelectedLooseItemLocationIds?.filter((id) => id !== rowSelectedLocationId) || [];

      return looseItemLocations.filter(({ id }) => !excludedLocationIds.includes(id));
    },
    [fields, getLooseItemLocations, getValues]
  );

  const handleLocationSelect = (name, value, index) => {
    const requiredQuantity = getValues(`${name}.requiredQuantity`);
    update(index, { ...value, requiredQuantity, ...DEFAULT_QUANTITIES });
    clearRowErrors(ERROR_RESET_TYPES.CHANGE_LOCATION, index);
  };

  const shouldDisplayAddButton = (looseItemId, index) => {
    const locationsNumber = getLooseItemLocations(looseItemId)?.length;
    const displayedNumber = getSelectedLooseItemLocations(looseItemId)?.length;

    const isLastDisplayedLocationRow =
      fields?.[index]?.looseItem?.id !== fields?.[index + 1]?.looseItem?.id;

    return isLastDisplayedLocationRow && locationsNumber > 1 && locationsNumber !== displayedNumber;
  };

  const handleAddClick = (looseItemId, index) => {
    const requiredQuantity = getValues(`${FIELD_ARRAY_NAME}[${index}].requiredQuantity`);

    const locations = getLooseItemLocations(looseItemId);
    const displayedIds = getSelectedLooseItemLocations(looseItemId).map(({ id }) => id);

    const availableLocations = locations.filter(({ id }) => !displayedIds.includes(id));
    insert(index + 1, { ...availableLocations[0], requiredQuantity, ...DEFAULT_QUANTITIES });
    clearRowErrors(ERROR_RESET_TYPES.ADD_TABLE_ROW, index);
  };

  const shouldDisplayRemoveButton = (looseItemId) =>
    getSelectedLooseItemLocations(looseItemId)?.length > 1;

  const handleRemoveClick = (index) => {
    remove(index);
    clearRowErrors(ERROR_RESET_TYPES.REMOVE_TABLE_ROW, index);
  };

  return (
    <div className={styles.looseItems}>
      <TableContainer className={styles.table} component={Paper}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Loose Item</StyledTableCell>
              <StyledTableCell>Required QTY</StyledTableCell>
              <StyledTableCell>Point of origin (Sublocation) [Asset]</StyledTableCell>
              <StyledTableCell>Available QTY</StyledTableCell>
              <StyledTableCell>Selected QTY</StyledTableCell>
              <StyledTableCell>
                From BAA<span className={styles.footnote}>[1]</span>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {fields.map(({ id, looseItem, requiredQuantity, quantity, byAheadAccount }, index) => {
              const { highlightSection, highlightRow } = getCellHighlightState(looseItem.id, index);

              return (
                <StyledTableRow key={id}>
                  {isUniqueCell(index) && (
                    <StyledTableCell
                      rowSpan={getRowSpan(index)}
                      className={clsx(styles.looseItemCell, highlightSection && styles.invalid)}>
                      <div className={styles.block_with_error}>
                        {looseItem.name}
                        {highlightSection && (
                          <ErrorBlock
                            errorText={
                              errors.looseItemIds[setLooseItemSectionId(looseItem.id)].message
                            }
                          />
                        )}
                      </div>
                    </StyledTableCell>
                  )}
                  {isUniqueCell(index) && (
                    <StyledTableCell
                      rowSpan={getRowSpan(index)}
                      className={clsx(styles.requiredQtyCell, highlightSection && styles.invalid)}>
                      {requiredQuantity}
                    </StyledTableCell>
                  )}
                  <StyledTableCell
                    className={clsx(
                      styles.originCell,
                      (highlightSection || highlightRow) && styles.invalid
                    )}>
                    <FormSearchInput
                      name={`${FIELD_ARRAY_NAME}[${index}]`}
                      options={getSearchOptions(index)}
                      onSelect={handleLocationSelect}
                      classes={styles.location_field}
                      index={index}
                      optionType="locationWithSublocationAndContainedInAsset"
                      disableError
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(
                      styles.availableQtyCell,
                      (highlightSection || highlightRow) && styles.invalid
                    )}>
                    {quantity}
                    {!!byAheadAccount && `${' (' + byAheadAccount + ')'}`}
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(
                      styles.selectedQtyCell,
                      (highlightSection || highlightRow) && styles.invalid
                    )}>
                    <div className={styles.block_with_error}>
                      <FormInputText
                        name={`${FIELD_ARRAY_NAME}[${index}].selectedQuantity`}
                        options={{
                          focus: true,
                          type: 'quantity',
                          disableError: true,
                          onlyNumbers: true,
                          skipClearingError: true,
                          index
                        }}
                        onInputTriggered={checkSelectedQuantity}
                        classes={styles.quantity_field}
                      />
                      {highlightRow && errors?.looseItems?.[id]?.selectedQuantity?.message && (
                        <ErrorBlock
                          className={styles.errorContainer}
                          errorText={errors.looseItems[id].selectedQuantity.message}
                        />
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(
                      styles.baaQtyCell,
                      (highlightSection || highlightRow) && styles.invalid
                    )}>
                    <div className={styles.block_with_error}>
                      <div className={styles.content}>
                        <FormInputText
                          name={`${FIELD_ARRAY_NAME}[${index}].selectedByAheadAccount`}
                          options={{
                            focus: true,
                            type: 'quantity',
                            disableError: true,
                            onlyNumbers: true,
                            skipClearingError: true,
                            index
                          }}
                          onInputTriggered={checkBaaQuantity}
                          classes={styles.quantity_field}
                        />

                        <div className={styles.controls}>
                          {shouldDisplayAddButton(looseItem.id, index) && (
                            <IconButton onClick={() => handleAddClick(looseItem.id, index)}>
                              <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                          )}
                          {shouldDisplayRemoveButton(looseItem.id) && (
                            <IconButton onClick={() => handleRemoveClick(index)}>
                              <CancelIcon />
                            </IconButton>
                          )}
                        </div>
                      </div>

                      {highlightRow &&
                        errors?.looseItems?.[id]?.selectedByAheadAccount?.message && (
                          <ErrorBlock
                            className={styles.errorContainer}
                            errorText={errors.looseItems[id].selectedByAheadAccount.message}
                          />
                        )}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Comment label="[1]Buy Ahead Account" />
    </div>
  );
}
