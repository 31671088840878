import React, { useRef } from 'react';
import styles from './LooseItems.module.scss';

import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import LinkComponent from 'components/LinkComponent/LinkComponent';

import { getChangedByPageFilter, getChangedBySortFilter } from 'helpers/SummaryPagesHelpers';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';

import { ASSET_LOOSE_ITEMS_TABLE_PARAMETER } from 'constants/configTableConstants';

import { LOOSE_ITEM_DETAILED_PATH } from 'constants/routeConstants';

const tableHeadConfig = [
  { id: 1, label: 'Loose Items', isSortable: true, parameter: 'name' },
  { id: 2, label: 'Status', isSortable: false },
  { id: 3, label: 'Contained In QTY', isSortable: false }
];

export default function AssetLooseItems() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);

  const { getTableLimit } = useUserConfig();

  const { looseItems, looseItemsFilter } = useAssetSelector();
  const { getLooseItems } = useAssetActions();

  const emptyResponse = () =>
    !looseItems?.items?.length && looseItemsFilter?.pagination?.page === looseItems?.pageNumber;

  const changePage = (page, param) =>
    getLooseItems(getChangedByPageFilter(looseItemsFilter, page), param);
  const sortColumn = (rowName) => getLooseItems(getChangedBySortFilter(looseItemsFilter, rowName));

  const getUpdatedByLimitFilter = () => ({
    ...looseItemsFilter,
    pagination: {
      ...looseItemsFilter.pagination,
      page: 1,
      limit: getTableLimit(ASSET_LOOSE_ITEMS_TABLE_PARAMETER)
    },
    isDataReceived: false
  });

  return (
    <>
      <TableContainer className={styles.table} component={Paper}>
        <Table>
          <TableHeadComponent
            config={tableHeadConfig}
            sortRules={looseItemsFilter.sortRules}
            onSortApply={sortColumn}
          />
          <TableBody>
            {looseItems?.items?.length ? (
              looseItems.items.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <LinkComponent
                      name={row?.looseItem?.name || ''}
                      path={`${LOOSE_ITEM_DETAILED_PATH}/${row.looseItem.id}`}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row?.status?.displayName || ''}</StyledTableCell>
                  <StyledTableCell>{row?.quantity || 0}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={10} className={styles.empty}>
                  No available data in the table.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.footer}>
        <TablePaginationRefactored
          isMobile={isMobile}
          rows={looseItems?.items?.length}
          currentPage={looseItemsFilter?.pagination?.page}
          totalPages={looseItemsFilter?.pagination?.totalPages}
          selectAllOption
          onPageSelect={changePage}
          pageLimit={looseItemsFilter?.pagination?.limit}
          hide={emptyResponse()}
          tableListParameter={ASSET_LOOSE_ITEMS_TABLE_PARAMETER}
          getTableData={() => getLooseItems(getUpdatedByLimitFilter())}
          tableRef={tableRef}
        />
      </div>
    </>
  );
}
