import {
  SET_CURRENT_PROJECT,
  CLEAR_PROJECTS_STATE,
  SET_PROJECTS_FILTER,
  SET_PROJECTS_FILTER_CRITERIA,
  SET_PROJECTS_STATES
} from 'constants/reduceConstants';

const initialState = {
  filter: {},
  filterCriteria: {},
  currentProject: {},
  states: []
};

const projectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // Summary
    case SET_PROJECTS_FILTER:
      return { ...state, filter: payload };
    case SET_PROJECTS_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    // Edit
    case SET_PROJECTS_STATES:
      return { ...state, states: payload };
    // Common
    case SET_CURRENT_PROJECT:
      return { ...state, currentProject: payload };
    case CLEAR_PROJECTS_STATE:
      return { ...initialState, filter: state.filter };
    default:
      return state;
  }
};

export default projectReducer;
