import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FormLabel, FormRow } from 'components/FormComponents';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';

import { FormProvider, useForm } from 'react-hook-form';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useAssembledBOMSelector } from 'hooks/AssembledBOM';

import { enrichNameToId } from 'helpers/AppHelpers';

import { defaultFilter } from '../helpers';

export function Filter({ open, setOpen, onApply }) {
  const isMobile = useMobileViewport();

  const methods = useForm({
    defaultValues: { ...defaultFilter },
    mode: 'onChange'
  });
  const { getValues, reset, setValue } = methods;

  const { filter, filterCriteria } = useAssembledBOMSelector();
  const { locations, statuses, configurations } = filterCriteria;

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = { ...defaultFilter.filters, searchQuery: getValues('searchQuery') };
    reset(defaultState);
  };

  const handleMultiSelect = (name, value) => setValue(name, value);

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <FormRow>
                <FormLabel>Location</FormLabel>
                <FormAutoMultiSelect
                  name="locationIds"
                  menuItems={locations || []}
                  options={{
                    label: 'siteCode',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Job Number</FormLabel>
                <FormAutoMultiSelect
                  name="locationJobNumberIds"
                  menuItems={locations || []}
                  options={{
                    label: 'locationJobNumber',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
            </div>
            <div className={styles.form__block}>
              <FormRow>
                <FormLabel>Status</FormLabel>
                <FormMultiSelect
                  trackById
                  name="statusNames"
                  options={enrichNameToId(statuses) || []}
                  onSelect={handleMultiSelect}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Configurations</FormLabel>
                <FormAutoMultiSelect
                  name="looseItemConfigurationIds"
                  menuItems={configurations || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
