import React, { useEffect, useState } from 'react';
import styles from './DeliveryBlocks.module.scss';

import Subhead from 'components/DetailsComponents/Subhead';
import { FormLabel, FormRow } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';

import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { selectCreationFormData } from 'pages/PicklistAndLeavelist/BillOfLading/selectors';

import { pluck } from 'helpers/AppHelpers';

import { BOL_TYPES } from '../helpers';

export function LooseItems({ type, excludedOptions }) {
  const { setValue, getValues, clearErrors, formState } = useFormContext();
  const { isValid } = formState;

  const isPicklist = type === BOL_TYPES.picklist;
  const isCustom = type === BOL_TYPES.custom;

  const { excludedLooseItemIds } = excludedOptions;

  const [options, setOptions] = useState({
    locations: [],
    sublocations: [],
    allLooseItems: [],
    looseItems: [],
    categories: []
  });

  const { locations, sublocations, allLooseItems, looseItems, categories } = options;

  const {
    // locations
    locationsWithSublocations = [],
    picklistLooseItemsOriginLocations = [],
    // loose items
    looseItemsForAdding = [],
    looseItems: looseItemOptions = [],
    // other
    typeOfVehicles = [],
    looseItemCategories = []
  } = useSelector(selectCreationFormData());

  useEffect(() => {
    if (!typeOfVehicles?.length) return;

    const excludedKey = isPicklist ? 'excludedPicklistAssetIds' : 'excludedLeavelistAssetIds';
    const key = isPicklist ? 'picklistAsset' : 'leavelistAsset';
    const locations = isPicklist ? picklistLooseItemsOriginLocations : locationsWithSublocations;

    const picklistLooseItems = looseItemsForAdding.filter(
      (el) => !excludedOptions[excludedKey].includes(el[key].id)
    );
    const customLooseItems = looseItemOptions.filter(
      ({ id }) => !excludedLooseItemIds.includes(id)
    );

    const allLooseItems = isCustom ? customLooseItems : picklistLooseItems;

    setOptions({
      locations,
      sublocations: [],
      looseItems: allLooseItems,
      allLooseItems,
      categories: looseItemCategories
    });
  }, [typeOfVehicles]);

  const conditions = {
    narrowByLocation: isPicklist || isCustom,
    narrowBySublocation: isCustom
  };

  const getLooseItemsLabel = () => {
    const obj = {
      [BOL_TYPES.picklist]: `Picklist's Loose Items`,
      [BOL_TYPES.leavelist]: `Leavelist's Loose Items`,
      default: 'Loose Items'
    };

    return obj[type] || obj.default;
  };

  const getLooseItemsFieldName = () => {
    const obj = {
      [BOL_TYPES.picklist]: 'picklistLooseItems',
      [BOL_TYPES.leavelist]: 'leavelistLooseItems',
      default: 'looseItems'
    };

    return obj[type] || obj.default;
  };

  const handleLocationSelect = (_, value) => {
    if (isCustom) {
      const sublocations = value?.flatMap(({ sublocations }) => sublocations) || [];

      const ids = pluck(sublocations, 'id');
      const newValue = getValues('narrowLooseItemsBySublocationIds').filter((id) =>
        ids.includes(id)
      );
      setValue('narrowLooseItemsBySublocationIds', newValue);

      setOptions((prev) => ({ ...prev, sublocations }));
    }

    filterLooseItems();
  };

  const filterLooseItems = () => {
    const selectedLocationIds = getValues('narrowLooseItemsByLocations')
      .map((el) => el?.[isCustom ? 'id' : 'locationId'] || null)
      .filter(Boolean);

    const selectedSublocationIds = getValues('narrowLooseItemsBySublocationIds');
    const selectedCategoryIds = getValues('narrowLooseItemsByCategoryIds');

    if (
      selectedLocationIds?.length === 0 &&
      selectedSublocationIds?.length === 0 &&
      selectedCategoryIds?.length === 0
    ) {
      setOptions((prev) => ({ ...prev, looseItems: allLooseItems }));
      return;
    }

    const filtered = allLooseItems.filter((item) => {
      const locationMatch =
        selectedLocationIds?.length === 0 ||
        (isPicklist
          ? selectedLocationIds.includes(item.originLocationId)
          : selectedLocationIds.some((id) => item.locationIds.includes(id)));

      const sublocationMatch =
        selectedSublocationIds?.length === 0 ||
        selectedSublocationIds.some((id) => item.sublocationIds.includes(id));

      const categoryMatch =
        selectedCategoryIds?.length === 0 ||
        selectedCategoryIds.some((id) => item.looseItemCategoryIds.includes(id));

      return locationMatch && sublocationMatch && categoryMatch;
    });

    setOptions((prev) => ({ ...prev, looseItems: filtered }));
  };

  const clearAtLeastError = () => !isValid && clearErrors();

  return (
    <div className={styles.container}>
      <Subhead>Loose Items</Subhead>

      <div className={styles.looseItems}>
        <div className={styles.block}>
          {conditions.narrowByLocation && (
            <FormRow>
              <FormLabel>
                {isPicklist ? 'Narrow by Origin Location' : 'Narrow by Location'}
              </FormLabel>
              <FormAutoMultiSelect
                name="narrowLooseItemsByLocations"
                menuItems={locations || []}
                options={{
                  label: isCustom ? 'siteCode' : 'name',
                  disableByObjectTracker: false,
                  disableLabel: true,
                  extraAction: handleLocationSelect
                }}
              />
            </FormRow>
          )}

          {conditions.narrowBySublocation && (
            <FormRow>
              <FormLabel>Narrow by Sublocation</FormLabel>
              <FormAutoMultiSelect
                name="narrowLooseItemsBySublocationIds"
                menuItems={sublocations || []}
                options={{
                  label: 'name',
                  disableByObjectTracker: true,
                  disableLabel: true,
                  extraAction: filterLooseItems,
                  isDisabled: !sublocations?.length
                }}
              />
            </FormRow>
          )}

          <FormRow>
            <FormLabel>Narrow by Category</FormLabel>
            <FormAutoMultiSelect
              name="narrowLooseItemsByCategoryIds"
              menuItems={categories || []}
              options={{
                label: 'name',
                disableByObjectTracker: true,
                disableLabel: true,
                extraAction: filterLooseItems
              }}
            />
          </FormRow>
        </div>

        <div className={styles.block}>
          <FormRow>
            <FormLabel>{getLooseItemsLabel()}</FormLabel>
            <FormAutoMultiSelect
              name={getLooseItemsFieldName()}
              menuItems={looseItems || []}
              options={{
                label: 'name',
                disableByObjectTracker: false,
                disableLabel: true,
                hideErrorMessage: true,
                extraAction: clearAtLeastError
              }}
            />
          </FormRow>
        </div>
      </div>
    </div>
  );
}
