import React, { useEffect, useState } from 'react';
import styles from './SelectLooseItemsPopup.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import { FormLabel, FormMultipleAssetSearch } from 'components/FormComponents';

import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { getFilterCriteria, getLooseItems } from 'actions/selectLooseItemsPopupActions';

import { pluck } from 'helpers/AppHelpers';

export default function SelectLooseItemsPopup({ open, setOpen, onAdd, queryOptions }) {
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: {
      narrowLocationIds: [],
      narrowSublocationIds: [],
      narrowCategoryIds: [],
      looseItems: []
    },
    mode: 'onChange'
  });
  const { getValues, setValue, watch } = methods;

  const [options, setOptions] = useState({
    locations: [],
    sublocations: [],
    looseItemCategories: [],
    looseItems: [],
    assetIds: [],
    assetList: []
  });
  const { locations, sublocations, looseItemCategories, looseItems } = options;

  const getQueryFilters = ({
    looseItemCategoryIds = [],
    locationIds = [],
    sublocationIds = [],
    assetIds = []
  } = {}) => ({
    filters: {
      looseItemCategoryIds,
      locationIds,
      sublocationIds,
      assetIds,
      ...queryOptions
    }
  });

  const fetchLooseItems = async (query) => {
    const looseItems = await dispatch(getLooseItems(query));
    return looseItems;
  };

  useEffect(() => {
    dispatch(getFilterCriteria()).then((res) => {
      const { locations, looseItemCategories } = res;
      setOptions((prev) => ({ ...prev, locations, looseItemCategories }));
    });
  }, []);

  useEffect(async () => {
    const looseItems = await fetchLooseItems(getQueryFilters());
    setOptions((prev) => ({ ...prev, looseItems }));
  }, []);

  const closePopup = () => setOpen(false);
  const handleApply = () => {
    closePopup();
    onAdd(getValues('looseItems'));
  };

  const locationsWatcher = watch('narrowLocationIds');
  const looseItemsWatcher = watch('looseItems');

  const handleLocationSelect = async (_, value) => {
    const sublocations =
      locations
        ?.filter(({ id }) => value.includes(id))
        ?.flatMap(({ sublocations }) => sublocations) || [];

    const sublocationIds = pluck(sublocations, 'id');
    const newSublocationIdsValue = getValues('narrowSublocationIds').filter((id) =>
      sublocationIds.includes(id)
    );
    setValue('narrowSublocationIds', newSublocationIdsValue);

    const query = getQueryFilters({
      looseItemCategoryIds: getValues('narrowCategoryIds'),
      locationIds: value,
      sublocationIds: newSublocationIdsValue,
      assetIds: getValues('assetIds')
    });

    const looseItems = await fetchLooseItems(query);

    setOptions((prev) => ({ ...prev, sublocations, looseItems }));
  };

  const handleSublocationSelect = async (_, value) => {
    const query = getQueryFilters({
      looseItemCategoryIds: getValues('narrowCategoryIds'),
      locationIds: getValues('narrowLocationIds'),
      sublocationIds: value,
      assetIds: getValues('assetIds')
    });

    const looseItems = await fetchLooseItems(query);

    setOptions((prev) => ({ ...prev, looseItems }));
  };

  const handleCategorySelect = async (_, value) => {
    const query = getQueryFilters({
      looseItemCategoryIds: value,
      locationIds: getValues('narrowLocationIds'),
      sublocationIds: getValues('narrowSublocationIds'),
      assetIds: getValues('assetIds')
    });

    const looseItems = await fetchLooseItems(query);

    setOptions((prev) => ({ ...prev, looseItems }));
  };

  const handleAssetSelect = async (_, value) => {
    const query = getQueryFilters({
      looseItemCategoryIds: getValues('narrowCategoryIds'),
      locationIds: getValues('narrowLocationIds'),
      sublocationIds: getValues('narrowSublocationIds'),
      assetIds: value
    });

    const looseItems = await fetchLooseItems(query);

    setOptions((prev) => ({ ...prev, looseItems }));
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        <DialogHeader title="Select Loose Items" onClose={closePopup} />

        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__row}>
              <FormLabel>Narrow by Location</FormLabel>
              <FormAutoMultiSelect
                name="narrowLocationIds"
                menuItems={locations || []}
                options={{
                  label: 'siteCode',
                  disableByObjectTracker: true,
                  disableLabel: true,
                  extraAction: handleLocationSelect
                }}
              />
            </div>
            <div className={styles.form__row}>
              <FormLabel>Narrow by Sublocation</FormLabel>
              <FormAutoMultiSelect
                name="narrowSublocationIds"
                menuItems={sublocations || []}
                options={{
                  label: 'name',
                  disableByObjectTracker: true,
                  disableLabel: true,
                  isDisabled: !locationsWatcher?.length,
                  extraAction: handleSublocationSelect
                }}
              />
            </div>
            <div className={styles.form__row}>
              <FormLabel>Narrow by Asset</FormLabel>
              <FormMultipleAssetSearch
                name="assetIds"
                listName="assetList"
                onSelectTriggered={handleAssetSelect}
                getQueryOptions={(searchQuery) => ({
                  pagination: { limit: 40, page: 1 },
                  filters: { isDeleted: false, prefixTypes: [2], searchQuery }
                })}
              />
            </div>
            <div className={styles.form__row}>
              <FormLabel>Narrow by Category</FormLabel>
              <FormAutoMultiSelect
                name="narrowCategoryIds"
                menuItems={looseItemCategories || []}
                options={{
                  label: 'name',
                  disableByObjectTracker: true,
                  disableLabel: true,
                  extraAction: handleCategorySelect
                }}
              />
            </div>
            <div className={styles.form__row}>
              <FormLabel>Loose items</FormLabel>
              <FormAutoMultiSelect
                name="looseItems"
                menuItems={looseItems || []}
                options={{
                  label: 'name',
                  disableByObjectTracker: false,
                  disableLabel: true
                }}
              />
            </div>
          </div>
        </FormProvider>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={closePopup} type="secondary" />
          <MainButton
            text="Add"
            action={handleApply}
            type="primary"
            isDisabled={!looseItemsWatcher?.length}
          />
        </div>
      </div>
    </DialogWrapper>
  );
}
