import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectSingleProjectData } from '../selectors';
import styles from './ProjectDetailed.module.scss';
import Header from './components/Header';
import Details from './components/Details';
import Notes from './components/Notes';
import LocationsTable from './components/LocationsTable';
import { useProjectActions } from 'hooks/Project';

export default function ProjectDetailed() {
  const { id } = useParams();
  const currentProject = useSelector(selectSingleProjectData());
  const { getProjectAction, clearStateAction } = useProjectActions();

  useEffect(() => {
    if (id) {
      getProjectAction(id);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <>
      <section className={styles.pageContainer}>
        {currentProject?.id === +id && (
          <>
            <Header />
            <Details />
            <Notes />
            <LocationsTable />
          </>
        )}
      </section>
    </>
  );
}
