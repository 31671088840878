import api from '../api';
import { browserHistory } from '../browserHistory';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';
import {
  CLEAR_PROJECTS_STATE,
  SET_CURRENT_PROJECT,
  SET_LOAD_MORE_FLAG,
  SET_PROJECTS_FILTER,
  SET_PROJECTS_FILTER_CRITERIA,
  SET_PROJECTS_STATES
} from 'constants/reduceConstants';
import {
  SUCCESS_CREATE_PROJECT,
  SUCCESS_DELETE_PROJECT,
  SUCCESS_UPDATE_PROJECT,
  SUCCESS_UPDATE_PROJECT_LOCATIONS
} from 'constants/infoSnackbarData';

import { PROJECT_DETAILED_PATH, PROJECT_LIST_PATH } from '../constants/routeConstants';

// Summary Screen
export const getProjects = (query) => (dispatch) =>
  api.project
    .getProjectsApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getLocations = (query) => () =>
  api.project
    .getLocationsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const setFilter = (data) => (dispatch) =>
  dispatch({ type: SET_PROJECTS_FILTER, payload: data });

export const setFilterCriteria = (data) => (dispatch) =>
  dispatch({ type: SET_PROJECTS_FILTER_CRITERIA, payload: data });

// Details Screen
export const getProjectLocations = (query) => () =>
  api.locations
    .getLocationsListApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const updateProjectLocations = (data) => () =>
  api.project.updateProjectLocationsApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_PROJECT_LOCATIONS);
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

export const deleteProject = (id) => () =>
  api.project.deleteProjectApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_PROJECT);
      browserHistory.push(PROJECT_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );

// Edit Screen
export const getStates = () => (dispatch) =>
  api.project
    .getStatesListApi()
    .then((res) => dispatch({ type: SET_PROJECTS_STATES, payload: res }))
    .catch((err) => console.log(err));

export const createProject = (data) => () =>
  api.project.createProjectApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_PROJECT);
      browserHistory.push(`${PROJECT_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

export const updateProject = (data) => () =>
  api.project.updateProjectApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_PROJECT);
      browserHistory.push(`${PROJECT_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

// Common
export const getSingleProject = (id) => (dispatch) =>
  api.project
    .getSingleProjectApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_PROJECT, payload: res }))
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      browserHistory.push(PROJECT_LIST_PATH);
    });

export const clearState = () => (dispatch) => dispatch({ type: CLEAR_PROJECTS_STATE });
