import { useCallback, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest, graphConfig } from 'msConfig';
import axios from 'axios';
import { useCalendarActions } from './useCalendarActions';

export const useMicrosoftCalendarApi = (teamMembers) => {
  const token = useRef(null);
  const { instance, accounts } = useMsal();

  const setToken = (t) => (token.current = t);

  const callMicrosoftGraph = useCallback(() => {
    if (!token.current) return;
    return axios.create({
      baseURL: graphConfig.graphMeEndpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token.current}`
      }
    });
  }, [token.current]);

  const initializeToken = useCallback(async () => {
    try {
      const request = { ...loginRequest, account: accounts[0] };
      const response = await instance.acquireTokenPopup(request);
      setToken(response.accessToken);
    } catch (err) {
      console.error('Failed to acquire token', err);
    }
  }, [instance, accounts]);

  const shareEventsWithMicrosoft = useCallback(async () => {
    try {
      await initializeToken();
      const { getCalendars } = useCalendarActions(callMicrosoftGraph, teamMembers);
      await getCalendars();
    } catch (err) {
      console.error('Failed to share events', err);
    }
  }, [initializeToken, callMicrosoftGraph, teamMembers]);

  return { shareEventsWithMicrosoft };
};
