import React, { useEffect, useState } from 'react';
import styles from './LocationsTable.module.scss';

import { MainButton, StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { LOCATION_DETAILED_PATH, LOCATION_EDIT_PATH } from 'constants/routeConstants';

import { useUserConfig } from 'hooks/useUserConfig';
import { useProjectActions } from 'hooks/Project';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectSingleProjectData } from '../../../selectors';

import { cloneObj } from 'helpers/AppHelpers';

export default function LocationsTable() {
  const { id } = useParams();

  const { isAdminUser } = useUserConfig();

  const { getProjectLocationAction, updateProjectLocationsAction, setActiveProjectAction } =
    useProjectActions();

  const history = useHistory();

  const [changedRows, setChangedRows] = useState([]);
  const [tableState, setTableState] = useState([]);
  const [initialState, setInitialState] = useState([]);

  const currentProject = useSelector(selectSingleProjectData());

  useEffect(() => {
    getProjectLocationAction({ filters: { projectId: id } }).then((res) => {
      setTableState(res?.items || []);
      setInitialState(res?.items || []);
    });
  }, []);

  const handleAddLocationClick = () => {
    setActiveProjectAction(currentProject);
    history.push(LOCATION_EDIT_PATH);
  };

  const handleCheckboxClick = (column, rowIndex) => {
    const newTableState = cloneObj(tableState);
    newTableState[rowIndex][column] = !newTableState[rowIndex][column];
    setTableState(newTableState);

    const rowId = initialState[rowIndex].id;
    if (!changedRows.includes(rowId)) {
      setChangedRows((prevChangedRows) => [...prevChangedRows, rowId]);
    } else {
      const originalRow = initialState.find((row) => row.id === rowId);
      const currentRow = newTableState[rowIndex];
      if (
        originalRow['onSiteReport'] === currentRow['onSiteReport'] &&
        originalRow['isProjectReport'] === currentRow['isProjectReport']
      ) {
        setChangedRows((prevChangedRows) => prevChangedRows.filter((id) => id !== rowId));
      }
    }
  };

  const saveChangesClick = () => {
    const changedData = tableState.filter(({ id }) => changedRows.includes(id));
    updateProjectLocationsAction(changedData).then(() => {
      setInitialState(tableState);
      setChangedRows([]);
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <label>Locations at this project</label>
        <div className={styles.header__controls}>
          {!!changedRows.length && (
            <MainButton text="save changes" action={saveChangesClick} type="blue_type" />
          )}
          {isAdminUser && (
            <MainButton text="Add location" action={handleAddLocationClick} type="primary" />
          )}
        </div>
      </div>
      <TableContainer className={styles.table} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Site code</StyledTableCell>
              <StyledTableCell>Location</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Location Report</StyledTableCell>
              <StyledTableCell>Project Report</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableState?.length ? (
              tableState.map((row, rowIndex) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    <LinkComponent
                      name={row?.siteCode || ''}
                      isRedirectAvailable={!row.deleted}
                      path={`${LOCATION_DETAILED_PATH}/${row.id}`}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {!!row.city && <span>{row.city}</span>}
                    {!!row.city && <span>{'\u2000'}</span>}
                    {!!row.state && <span>{row.state}</span>}
                  </StyledTableCell>
                  <StyledTableCell>{row.description}</StyledTableCell>
                  <StyledTableCell>{row.siteType.name}</StyledTableCell>
                  <StyledTableCell
                    className={
                      row?.onSiteReport !== initialState?.[rowIndex]?.onSiteReport && styles.edited
                    }>
                    <CustomCheckbox
                      name="onSiteReport"
                      value={row['onSiteReport']}
                      onChange={(name) => handleCheckboxClick(name, rowIndex)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    className={
                      row?.['isProjectReport'] !== initialState?.[rowIndex]?.['isProjectReport'] &&
                      styles.edited
                    }>
                    <CustomCheckbox
                      name="isProjectReport"
                      value={row['isProjectReport']}
                      onChange={(name) => handleCheckboxClick(name, rowIndex)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={100} className={styles.empty}>
                  No available data in the table.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
