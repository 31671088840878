import React, { useState } from 'react';
import styles from './Form.module.scss';

import ToggleBar from 'components/ToggleBars/ToggleBar';

import {
  defaultTabState,
  TOGGLE_BUTTONS,
  ASSET_TAB_VALUE,
  LOOSE_ITEMS_TAB_VALUE
} from '../helpers';

import General from './General';
import AssetPrefixes from './AssetPrefixes';
import LooseItems from './LooseItems';

export function Form() {
  const [tabState, setTabState] = useState(defaultTabState);

  const clearTabState = () => setTabState(defaultTabState);

  const updateTabState = ({ maximumQuantity, looseItems, assetPrefixes }) => {
    if (maximumQuantity === 0) return;

    const hasLooseItems = Array.isArray(looseItems) && looseItems.length > 0;
    const hasAssets = Array.isArray(assetPrefixes) && assetPrefixes.length > 0;

    const shouldShowTabs = hasLooseItems && hasAssets;
    const activeTab = hasLooseItems ? LOOSE_ITEMS_TAB_VALUE : ASSET_TAB_VALUE;

    setTabState({
      active: shouldShowTabs,
      selected: shouldShowTabs ? LOOSE_ITEMS_TAB_VALUE : activeTab
    });
  };

  const setTab = (value) => setTabState((prev) => ({ ...prev, selected: value }));

  return (
    <div className={styles.form}>
      <General onLoad={clearTabState} onConfigurationChange={updateTabState} />

      <div className={styles.tables}>
        {tabState.active && (
          <ToggleBar
            classes={styles.tabs}
            buttons={TOGGLE_BUTTONS}
            selectedTab={tabState.selected}
            onChange={setTab}
          />
        )}

        {tabState.selected === LOOSE_ITEMS_TAB_VALUE && <LooseItems />}

        {tabState.selected === ASSET_TAB_VALUE && <AssetPrefixes />}
      </div>
    </div>
  );
}
