import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useAssembledBOMActions } from 'hooks/AssembledBOM';

import Wrapper from './Wrapper';
import Form from './Form';

export function AssembleCreate() {
  const methods = useForm({
    defaultValues: {
      // quantity field properties
      quantityOptions: [],
      selectedQuantityOption: {},
      // assemble properties
      looseItemConfiguration: {},
      quantity: 0,
      name: '',
      location: {},
      sublocation: null,
      notes: '',
      looseItems: [],
      assetPrefixes: []
    },
    mode: 'onChange'
  });

  const { getCreationFormAction, clearStateAction } = useAssembledBOMActions();

  useEffect(() => {
    getCreationFormAction();
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <Form />
      </Wrapper>
    </FormProvider>
  );
}
