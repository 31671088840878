export const looseItemsCellsStructure = [
  { value: 'Location' },
  { value: 'Sublocation' },
  { value: 'Contained In' },
  { value: 'Note' },
  { value: 'Status' },
  { value: 'Min QTY' },
  { value: 'BAA', footnote: '[1]' },
  { value: 'Current QTY' },
  { value: 'Location QTY' }
];
