import { useSelector } from 'react-redux';

import {
  selectFilterData,
  selectFilterCriteriaData
} from 'pages/LocationManagement/ProjectList/selectors';

export function useProjectSelector() {
  const filter = useSelector(selectFilterData());
  const filterCriteria = useSelector(selectFilterCriteriaData());

  return {
    filter,
    filterCriteria
  };
}
