import React, { createContext, useContext, useRef, useImperativeHandle } from 'react';
import { Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { checkElemPosition } from 'actions/commonActions';

import RoleGuard from 'guards/RoleGuard';
import PersonnelProfileGuard from 'guards/PersonnelProfileGuard';
import TeamScheduleGuard from 'guards/TeamScheduleGuard';
import LooseItemEditGuard from 'guards/LooseItemEditGuard';
import ReadOnlyRightUsersGuard from 'guards/ReadOnlyRightUsersGuard';

import ScrollToTop from 'helpers/ScrollToTop';
import ScrollToPosition from 'helpers/ScrollToPosition';

import LoaderSpinner from 'components/LoaderSpinner/LoaderSpinner';
import SnackbarWrapper from 'components/SnackbarWrapper';
import Header from 'components/Header/Header';
import LeftSidebar from 'components/LeftSidebar';
import NotFound from 'pages/NotFound/NotFound';
import * as routes from 'constants/routeConstants';
import Dashboard from 'pages/AssetsDashboards/General/Dashboard';
import BuildRouteMap from 'pages/LocationManagement/BuildRouteMap/BuildRouteMap';
import CategoryList from 'pages/PrefixCategoryList/CategoryList';
import CategoryEdit from 'pages/PrefixCategoryList/CategoryEdit/CategoryEdit';
import CategoryDetailed from 'pages/PrefixCategoryList/CategoryDetailed/CategoryDetailed';
import PrefixEdit from 'pages/PrefixCategoryList/PrefixEdit/PrefixEdit';
import PrefixDetails from 'pages/PrefixCategoryList/PrefixDetailed/PrefixDetailed';
import LocationsList from 'pages/LocationManagement/LocationSummary';
import LocationDetailed from 'pages/LocationManagement/LocationSummary/LocationDetailed/LocationDetailed';
import LocationEdit from 'pages/LocationManagement/LocationSummary/LocationEdit/LocationEdit';
import ProjectList from 'pages/LocationManagement/ProjectList/Summary';
import ProjectDetailed from 'pages/LocationManagement/ProjectList/ProjectDetailed/ProjectDetailed';
import ProjectEdit from 'pages/LocationManagement/ProjectList/ProjectEdit/ProjectEdit';
import AssetsList from 'pages/AssetSummary/AssetsList';
import AssetDetailed from 'pages/AssetSummary/AssetDetailed/AssetDetailed';
import LocationReportList from 'pages/ReportLists/LocationReportList/LocationReportList';
import LocationReportEdit from 'pages/ReportLists/LocationReportList/LocationReportEdit/LocationReportEdit';
import AssetReportList from 'pages/ReportLists/AssetReportList/AssetReportList';
import AssetReportEdit from 'pages/ReportLists/AssetReportList/AssetReportEdit/AssetReportEdit';
import GeneralReportList from 'pages/ReportLists/GeneralReportList/GeneralReportList';
import GeneralReportEdit from 'pages/ReportLists/GeneralReportList/GeneralReportEdit/GeneralReportEdit';
import CompareReportsSelector from 'pages/ReportLists/CompareReports/CompareReportsSelector';
import ReportTemplateList from 'pages/ReportLists/ReportTemplateList';
import ReportTemplateEditor from 'pages/ReportLists/ReportTemplateList/ReportTemplateEditor/ReportTemplateEditor';
import TicketsList from 'pages/AssetTickets/AssetTicketSummary/TicketSummary';
import TicketDetailed from 'pages/AssetTickets/AssetTicketSummary/TicketDetailed/TicketDetailed';
import TicketEdit from 'pages/AssetTickets/AssetTicketSummary/TicketEdit';
import BatchAssetUpdates from 'pages/BatchUpdates/Asset/BatchAssetUpdates';
import UserList from 'pages/UserManagement/UserList/UserList';
import UserDetailed from 'pages/UserManagement/UserList/UserDetailed/UserDetailed';
import UserEdit from 'pages/UserManagement/UserList/UserEdit/UserEdit';
import UserLogsSummary from 'pages/UserManagement/UserLogs/UserLogsSummary';
import LooseItemDashboard from 'pages/LooseItems/Dashboard/LooseItemDashboard';
import BOMConfigurations from 'pages/LooseItems/BOMConfigurations/BOMConfigurations';
import BOMConfigurationDetailed from 'pages/LooseItems/BOMConfigurations/ConfigurationDetailed/BOMConfigurationDetailed';
import BOMConfigurationEdit from 'pages/LooseItems/BOMConfigurations/ConfigurationEdit/BOMConfigurationEdit';
import AssembledBOMList from 'pages/LooseItems/AssembledBOM/Summary';
import AssembleCreate from 'pages/LooseItems/AssembledBOM/Create';
import AssembleDetailed from 'pages/LooseItems/AssembledBOM/AssembleDetailed/AssembleDetailed';
import PicklistSummary from 'pages/PicklistAndLeavelist/PicklistSummary';
import PicklistEdit from 'pages/PicklistAndLeavelist/PicklistSummary/PicklistEdit/PicklistEdit';
import PicklistDetailed from 'pages/PicklistAndLeavelist/PicklistSummary/PicklistDetailed/PicklistDetailed';
import LeaveListSummary from 'pages/PicklistAndLeavelist/LeavelistSummary';
import LeaveListEdit from 'pages/PicklistAndLeavelist/LeavelistSummary/LeavelistEdit/LeaveListEdit';
import LeaveListDetailed from 'pages/PicklistAndLeavelist/LeavelistSummary/LeavelistDetailed/LeaveListDetailed';
import TeamMemberList from 'pages/TeamSchedule/TeamList';
import TeamMemberEdit from 'pages/TeamSchedule/TeamList/TeamMemberEdit/TeamMemberEdit';
import TeamMemberDetailed from 'pages/TeamSchedule/TeamList/TeamMemberDetailed/TeamMemberDetailed';
import ProjectSchedule from 'pages/TeamSchedule/ProjectSchedule/ProjectSchedule';
import LooseItemsList from 'pages/LooseItems/LooseItemsList/Summary/LooseItemsList';
import LooseItemEdit from 'pages/LooseItems/LooseItemsList/LooseItemsFolder/LooseItemEdit';
import LooseItemDetails from 'pages/LooseItems/LooseItemsList/LooseItemsFolder/LooseItemDetails/LooseItemDetails';
import MovedLooseItems from 'pages/MovedLooseItems/MovedLooseItems';
import OverviewReport from 'pages/ReportLists/OverviewReport/OverviewReport';
import RequestOrderSummary from 'pages/RequestOrder/Summary/RequestOrderSummary';
import RequestOrderEdit from 'pages/RequestOrder/Create/RequestOrderCreate';
import RequestOrderDetails from 'pages/RequestOrder/RequestOrderDetails/RequestOrderDetails';
import TeamMemberDashboard from 'pages/TeamSchedule/Dashboard';
import BillOfLadingEdit from 'pages/PicklistAndLeavelist/BillOfLading/BillOfLadingEdit/BillOfLadingEdit';
import BillOfLadingDetails from 'pages/PicklistAndLeavelist/BillOfLading/BillOfLadingDetails/BillOfLadingDetails';
import BillOfLadingSummary from 'pages/PicklistAndLeavelist/BillOfLading/Summary';
import TeamMemberHistory from 'pages/TeamSchedule/TeamMemberHistory/TeamMemberHistory';
import AddBomToPicklist from 'pages/PicklistAndLeavelist/AddBomToPicklist/AddBomToPicklist';
import BatchLooseItemUpdates from 'pages/BatchUpdates/LooseItem/BatchLooseItemUpdates';
import CreateBatchOfAssets from 'pages/AssetSummary/CreateBatchOfAssets';
import NotificationCenter from 'pages/UserManagement/NotificationCenter';
import ExternalTrainings from 'pages/TeamSchedule/ExternalTrainings';
import TicketsDashboard from 'pages/AssetTickets/Dashboard';
import VerificationInspectionDashboards from 'pages/AssetsDashboards/VerificationAndInspection';
import ManageLooseItems from 'pages/PicklistAndLeavelist/ManageLooseItems';
import TicketTypesSummary from 'pages/AssetTickets/TicketTypesSummary';
import TicketTypeEdit from 'pages/AssetTickets/TicketTypesSummary/TicketTypeEdit';
import TicketRootCausesSummary from 'pages/AssetTickets/TicketRootCausesSummary';
import TicketRootCauseEdit from 'pages/AssetTickets/TicketRootCausesSummary/TicketRootCauseEdit';
import BillOfLadingLogs from 'pages/PicklistAndLeavelist/BillOfLadingLogs';
import BillOfLadingLogEdit from 'pages/PicklistAndLeavelist/BillOfLadingLogs/BillOfLadingLogEdit';
import UploadLogsFromCSV from 'pages/PicklistAndLeavelist/BillOfLadingLogs/UploadLogsFromCSV';
import AssetEdit from 'pages/AssetSummary/Edit';
import Search from 'pages/Search';
import Sandbox from 'pages/Sandbox';
import Home from 'pages/Home';
import ActiveLocationsMap from 'pages/LocationManagement/ActiveLocationsMap';

export const ContainerRefContext = createContext({ current: null });

function AuthorizedView() {
  const ContainerRefApi = useContext(ContainerRefContext);
  const containerRef = useRef(null);

  useImperativeHandle(ContainerRefApi, () => ({
    getRef: () => containerRef
  }));

  return (
    <>
      <ScrollToTop elem={containerRef} />
      <ScrollToPosition elem={containerRef} />
      <LoaderSpinner />
      <Header />
      <SnackbarWrapper>
        <section className="content">
          <LeftSidebar />
          <section
            className="mainContainer"
            ref={containerRef}
            onScroll={() => checkElemPosition(containerRef)}>
            <div className="mainContainer__root">
              <Switch>
                <Route path={routes.DASHBOARD_PATH} component={() => <Dashboard />} />
                <Route
                  path={routes.ASSETS_VERIFICATION_AND_INSPECTION_DASHBOARDS_PATH}
                  component={() => <VerificationInspectionDashboards />}
                />
                <Route path={routes.BUILD_ROUTE_MAP_PATH} component={() => <BuildRouteMap />} />
                <Route path={routes.CATEGORY_LIST_PATH} component={() => <CategoryList />} />
                <Route
                  path={`${routes.CATEGORY_EDIT_PATH}/:id?`}
                  component={() => <RoleGuard component={<CategoryEdit />} rolesAllowed={[1]} />}
                />
                <Route
                  path={`${routes.CATEGORY_DETAILED_PATH}/:id`}
                  component={() => <CategoryDetailed />}
                />
                <Route
                  path={`${routes.PREFIX_EDIT_PATH}/:id?`}
                  component={() => <RoleGuard component={<PrefixEdit />} rolesAllowed={[1]} />}
                />
                <Route
                  path={`${routes.PREFIX_DETAILED_PATH}/:id?`}
                  component={() => <PrefixDetails />}
                />
                <Route path={routes.LOCATIONS_LIST_PATH} component={() => <LocationsList />} />
                <Route
                  path={`${routes.LOCATION_DETAILED_PATH}/:id`}
                  component={() => <LocationDetailed />}
                />
                <Route
                  path={`${routes.LOCATION_EDIT_PATH}/:id?`}
                  component={() => <RoleGuard component={<LocationEdit />} rolesAllowed={[1, 4]} />}
                />
                <Route path={routes.PROJECT_LIST_PATH} component={() => <ProjectList />} />
                <Route
                  exact
                  path={routes.MANAGE_LOOSE_ITEMS_PATH}
                  component={() => <ManageLooseItems />}
                />
                <Route
                  exact
                  path={`${routes.PROJECT_DETAILED_PATH}/:id`}
                  component={() => <ProjectDetailed />}
                />
                <Route
                  path={`${routes.PROJECT_EDIT_PATH}/:id?`}
                  component={() => <RoleGuard component={<ProjectEdit />} rolesAllowed={[1, 4]} />}
                />
                <Route path={routes.ASSETS_LIST_PATH} component={() => <AssetsList />} />
                <Route
                  path={`${routes.ASSET_DETAILED_PATH}/:id`}
                  component={() => <AssetDetailed />}
                />
                <Route path={`${routes.ASSET_EDIT_PATH}/:id?`} component={() => <AssetEdit />} />
                <Route
                  path={routes.LOCATION_REPORT_LIST_PATH}
                  component={() => <LocationReportList />}
                />
                <Route
                  path={`${routes.LOCATION_REPORT_EDIT_PATH}/:id?`}
                  component={() => <LocationReportEdit />}
                />
                <Route path={routes.ASSET_REPORT_LIST_PATH} component={() => <AssetReportList />} />
                <Route
                  path={`${routes.ASSET_REPORT_EDIT_PATH}/:id?`}
                  component={() => <AssetReportEdit />}
                />
                <Route
                  path={routes.GENERAL_REPORT_LIST_PATH}
                  component={() => <GeneralReportList />}
                />
                <Route
                  path={`${routes.GENERAL_REPORT_EDIT_PATH}/:id?`}
                  component={() => <GeneralReportEdit />}
                />
                <Route
                  path={routes.COMPARE_REPORTS_PATH}
                  component={() => <CompareReportsSelector />}
                />
                <Route
                  path={`${routes.REPORT_TEMPLATE_LIST_PATH}`}
                  component={() => <ReportTemplateList />}
                />
                <Route
                  path={`${routes.REPORT_TEMPLATE_EDITOR}/:id?`}
                  component={() => (
                    <RoleGuard component={<ReportTemplateEditor />} rolesAllowed={[1]} />
                  )}
                />
                <Route path={routes.TICKETS_LIST_PATH} component={() => <TicketsList />} />
                <Route
                  path={`${routes.TICKET_DETAILED_PATH}/:id?`}
                  component={() => <TicketDetailed />}
                />
                <Route path={`${routes.TICKET_EDIT_PATH}/:id?`} component={() => <TicketEdit />} />
                <Route
                  exact
                  path={routes.BATCH_ASSET_UPDATES_PATH}
                  component={() => <BatchAssetUpdates />}
                />
                <Route
                  exact
                  path={routes.BATCH_LOOSE_ITEM_UPDATES_PATH}
                  component={() => <BatchLooseItemUpdates />}
                />
                <Route
                  path={routes.USER_LIST_PATH}
                  component={() => <RoleGuard component={<UserList />} rolesAllowed={[1]} />}
                />
                <Route
                  path={`${routes.USER_DETAILED_PATH}/:id`}
                  component={() => <RoleGuard component={<UserDetailed />} rolesAllowed={[1]} />}
                />
                <Route
                  path={`${routes.USER_EDIT_PATH}/:id?`}
                  component={() => <RoleGuard component={<UserEdit />} rolesAllowed={[1]} />}
                />
                <Route
                  path={routes.USER_LOGS_PATH}
                  component={() => <RoleGuard component={<UserLogsSummary />} rolesAllowed={[1]} />}
                />
                <Route
                  path={routes.TICKET_ROOT_CAUSES_PATH}
                  component={() => (
                    <RoleGuard component={<TicketRootCausesSummary />} rolesAllowed={[1, 4]} />
                  )}
                />
                <Route
                  path={`${routes.TICKET_ROOT_CAUSE_EDIT_PATH}/:id?`}
                  component={() => (
                    <RoleGuard component={<TicketRootCauseEdit />} rolesAllowed={[1, 4]} />
                  )}
                />
                <Route
                  path={routes.TICKET_TYPES_PATH}
                  component={() => (
                    <RoleGuard component={<TicketTypesSummary />} rolesAllowed={[1, 4]} />
                  )}
                />
                <Route
                  path={`${routes.TICKET_TYPE_EDIT_PATH}/:id?`}
                  component={() => (
                    <RoleGuard component={<TicketTypeEdit />} rolesAllowed={[1, 4]} />
                  )}
                />
                <Route
                  path={routes.LOOSE_ITEM_DASHBOARD}
                  component={() => <LooseItemDashboard />}
                />
                <Route
                  path={routes.BOM_CONFIGURATIONS_LIST_PATH}
                  component={() => <BOMConfigurations />}
                />
                <Route
                  path={`${routes.BOM_CONFIGURATION_DETAILED_PATH}/:id`}
                  component={() => <BOMConfigurationDetailed />}
                />
                <Route
                  path={`${routes.BOM_CONFIGURATION_EDIT_PATH}/:id?`}
                  component={() => (
                    <RoleGuard component={<BOMConfigurationEdit />} rolesAllowed={[1, 4]} />
                  )}
                />
                <Route
                  path={routes.ASSEMBLED_BOM_LIST_PATH}
                  component={() => <AssembledBOMList />}
                />
                <Route
                  path={`${routes.ASSEMBLE_CREATE_PATH}`}
                  component={() => (
                    <RoleGuard component={<AssembleCreate />} rolesAllowed={[1, 2, 4, 5]} />
                  )}
                />
                <Route
                  path={`${routes.ASSEMBLED_BOM_DETAILED_PATH}/:id`}
                  component={() => <AssembleDetailed />}
                />
                <Route path={routes.PICKLIST_SUMMARY_PATH} component={() => <PicklistSummary />} />
                <Route
                  path={`${routes.PICKLIST_EDIT_PATH}/:id?`}
                  component={() => <PicklistEdit />}
                />
                <Route
                  path={`${routes.PICKLIST_DETAILED_PATH}/:id`}
                  component={() => <PicklistDetailed />}
                />
                <Route
                  path={routes.LEAVELIST_SUMMARY_PATH}
                  component={() => <LeaveListSummary />}
                />
                <Route
                  path={`${routes.LEAVELIST_EDIT_PATH}/:id?`}
                  component={() => <LeaveListEdit />}
                />
                <Route
                  path={`${routes.LEAVELIST_DETAILED_PATH}/:id`}
                  component={() => <LeaveListDetailed />}
                />
                <Route
                  path={routes.TEAM_MEMBER_LIST_PATH}
                  component={() => <ReadOnlyRightUsersGuard component={<TeamMemberList />} />}
                />
                <Route
                  path={`${routes.TEAM_MEMBER_EDIT_PATH}/:id?`}
                  component={() => (
                    <RoleGuard component={<TeamMemberEdit />} rolesAllowed={[1, 5]} />
                  )}
                />
                <Route
                  path={`${routes.TEAM_MEMBER_DETAILED_PATH}/:id`}
                  component={() => (
                    <PersonnelProfileGuard
                      component={<TeamMemberDetailed />}
                      rolesAllowed={[1, 5]}
                    />
                  )}
                />
                <Route
                  path={routes.PERSONNEL_PROFILE_PATH}
                  component={() => <TeamMemberDetailed />}
                />
                <Route
                  path={routes.TEAM_MEMBER_TRACKER_PATH}
                  component={() => <TeamScheduleGuard component={<ProjectSchedule />} />}
                />
                <Route
                  path={routes.TEAM_MEMBER_DASHBOARD_PATH}
                  component={() => <ReadOnlyRightUsersGuard component={<TeamMemberDashboard />} />}
                />
                <Route path={routes.LOOSE_ITEMS_LIST_PATH} component={() => <LooseItemsList />} />
                <Route
                  path={`${routes.LOOSE_ITEM_EDIT_PATH}/:id?`}
                  component={() => <LooseItemEditGuard component={<LooseItemEdit />} />}
                />
                <Route
                  path={`${routes.LOOSE_ITEM_DETAILED_PATH}/:id`}
                  component={() => <LooseItemDetails />}
                />
                <Route
                  path={routes.MOVED_LOOSE_ITEMS_PATH}
                  component={() => (
                    <RoleGuard component={<MovedLooseItems />} rolesAllowed={[1, 4]} />
                  )}
                />
                <Route
                  path={routes.OVERVIEW_REPORT_PATH}
                  component={() => (
                    <RoleGuard component={<OverviewReport />} rolesAllowed={[1, 2, 4]} />
                  )}
                />
                <Route
                  path={routes.REQUEST_ORDER_SUMMARY_PATH}
                  component={() => <RequestOrderSummary />}
                />
                <Route
                  path={`${routes.REQUEST_ORDER_EDIT_PATH}/:id?`}
                  component={() => <RequestOrderEdit />}
                />
                <Route
                  path={`${routes.REQUEST_ORDER_DETAILED_PATH}/:id`}
                  component={() => <RequestOrderDetails />}
                />
                <Route
                  path={routes.BILL_OF_LADING_SUMMARY_PATH}
                  component={() => <BillOfLadingSummary />}
                />
                <Route
                  path={`${routes.BILL_OF_LADING_EDIT_PATH}/:id?`}
                  component={() => <BillOfLadingEdit />}
                />
                <Route
                  path={`${routes.BILL_OF_LADING_DETAILS_PATH}/:id`}
                  component={() => <BillOfLadingDetails />}
                />
                <Route
                  path={routes.TEAM_MEMBER_HISTORY_PATH}
                  component={() => <ReadOnlyRightUsersGuard component={<TeamMemberHistory />} />}
                />
                <Route
                  path={routes.ADD_BOM_TO_PICKLIST_PATH}
                  component={() => <AddBomToPicklist />}
                />
                <Route
                  path={routes.BATCH_OF_ASSETS_CREATION_PATH}
                  component={() => <CreateBatchOfAssets />}
                />
                <Route
                  path={routes.NOTIFICATION_CENTER_PATH}
                  component={() => (
                    <RoleGuard component={<NotificationCenter />} rolesAllowed={[1]} />
                  )}
                />
                <Route
                  path={routes.TRAININGS_AND_CERTIFICATIONS_PATH}
                  component={() => (
                    <RoleGuard component={<ExternalTrainings />} rolesAllowed={[1]} />
                  )}
                />
                <Route
                  path={routes.TICKETS_DASHBOARD_PATH}
                  component={() => <TicketsDashboard />}
                />
                <Route
                  path={routes.BILL_OF_LADING_LOGS_PATH}
                  component={() => <BillOfLadingLogs />}
                />
                <Route
                  path={`${routes.BILL_OF_LADING_LOG_EDIT_PATH}/:id?`}
                  component={() => <BillOfLadingLogEdit />}
                />
                <Route
                  path={routes.UPLOAD_BOL_LOGS_FROM_CSV_PATH}
                  component={() => <UploadLogsFromCSV />}
                />
                <Route
                  path={routes.ACTIVE_LOCATIONS_MAP_PATH}
                  component={() => <ActiveLocationsMap />}
                />
                <Route path={routes.SEARCH_PATH} component={() => <Search />} />
                <Route path={routes.NOT_FOUND_PATH} component={NotFound} />
                <Route path={routes.HOME_PATH} component={() => <Home />} />
                <Route
                  exact
                  path={routes.ROOT_PATH}
                  render={() => <Redirect to={routes.HOME_PATH} />}
                />
                <Route path={routes.SANDBOX_PATH} component={() => <Sandbox />} />
                <Route path="*" render={() => <Redirect to={routes.NOT_FOUND_PATH} />} />
              </Switch>
            </div>
          </section>
        </section>
      </SnackbarWrapper>
    </>
  );
}

export default AuthorizedView;
