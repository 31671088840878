import React from 'react';
import styles from './LevelsTable.module.scss';

import { NoDataTableRow, TableWrapper } from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import StyledLink from 'components/StyledLink';

import { Table, TableHead, TableBody } from '@material-ui/core';

import { useUserConfig } from 'hooks/useUserConfig';

import { getProjectName } from 'helpers/AppHelpers';

import { generateCellUrl } from '../../../../helpers';

const EmptyTable = () => (
  <div className={styles.table}>
    <TableWrapper>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Job</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <NoDataTableRow isTableFiltered={false} />
        </TableBody>
      </Table>
    </TableWrapper>
  </div>
);

export default function StaffingLevelsTable({ dashboard, dashboardId, department }) {
  if (!('staffingLevelsProjects' in dashboard)) {
    return <EmptyTable />;
  }

  const { isAdminUser, isPersonnelManagerUser } = useUserConfig();

  const { duration1ColumnName, duration2ColumnName, staffingLevelsProjects, total } = dashboard;

  const generateUrlParams = (options) => ({
    dashboardId,
    dashboardType: 'StaffingLevelsAndAvailability',
    ...options
  });

  const shouldAllowRedirect = (value = 0) => (isAdminUser || isPersonnelManagerUser) && value > 0;

  return (
    <div className={styles.table}>
      <TableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Job</StyledTableCell>
              <StyledTableCell>{duration1ColumnName || ''}</StyledTableCell>
              <StyledTableCell>{duration2ColumnName || ''}</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {staffingLevelsProjects?.length ? (
              <>
                {staffingLevelsProjects.map(({ project, staffingLevelsDurationValues }, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell className={styles.projectCell}>
                      {getProjectName(project)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledLink
                        to={generateCellUrl(
                          generateUrlParams({
                            dashboardDuration: 1,
                            dashboardProjectId: project.id
                          })
                        )}
                        isRedirectAvailable={shouldAllowRedirect(
                          staffingLevelsDurationValues?.duration1Value
                        )}>
                        {staffingLevelsDurationValues?.duration1Value || 0}
                      </StyledLink>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledLink
                        to={generateCellUrl(
                          generateUrlParams({
                            dashboardDuration: 2,
                            dashboardProjectId: project.id
                          })
                        )}
                        isRedirectAvailable={shouldAllowRedirect(
                          staffingLevelsDurationValues?.duration2Value
                        )}>
                        {staffingLevelsDurationValues?.duration2Value || 0}
                      </StyledLink>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow className={styles.total}>
                  <StyledTableCell>GRAND TOTAL WORKING</StyledTableCell>
                  <StyledTableCell>{total?.duration1Value || 0}</StyledTableCell>
                  <StyledTableCell>{total?.duration2Value || 0}</StyledTableCell>
                </StyledTableRow>
              </>
            ) : (
              <NoDataTableRow isTableFiltered={!!department?.id} />
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
}
