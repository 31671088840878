import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FormDayPicker, FormLabel } from 'components/FormComponents';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';

import { FormProvider, useForm } from 'react-hook-form';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { defaultFilter } from '../helpers';
import { useBillOfLadingSelector } from 'hooks/BillOfLading';

export function Filter({ open, setOpen, onApply }) {
  const isMobile = useMobileViewport();

  const methods = useForm({
    defaultValues: { ...defaultFilter },
    mode: 'onChange'
  });
  const { getValues, reset, watch } = methods;

  const { filter, filterCriteria } = useBillOfLadingSelector();
  const { destinationLocations, leavelists, originLocations, picklists } = filterCriteria;

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = { ...defaultFilter.filters, searchQuery: getValues('searchQuery') };
    reset(defaultState);
  };

  const createdFromWatcher = watch('createdFrom');
  const createdToWatcher = watch('createdTo');

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Destination</FormLabel>
                <FormAutoMultiSelect
                  name="destinationLocationIds"
                  menuItems={destinationLocations || []}
                  options={{
                    label: 'siteCode',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Origin Location</FormLabel>
                <FormAutoMultiSelect
                  name="originLocationIds"
                  menuItems={originLocations || []}
                  options={{
                    label: 'siteCode',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Picklist Name</FormLabel>
                <FormAutoMultiSelect
                  name="picklistIds"
                  menuItems={picklists || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Leavelist Name</FormLabel>
                <FormAutoMultiSelect
                  name="leavelistIds"
                  menuItems={leavelists || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Created from</FormLabel>
                <FormDayPicker name="createdFrom" max={createdToWatcher || new Date()} />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Created to</FormLabel>
                <FormDayPicker name="createdTo" min={createdFromWatcher} max={new Date()} />
              </div>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
