import { PROJECT_LIST_TABLE_PARAMETER } from 'constants/configTableConstants';
import { cloneObj } from 'helpers/AppHelpers';
import { handleBooleanFilter, handleIdsFilter } from 'helpers/ChipGenerator';
import { useSearchParams } from 'hooks/useSearchParams';

const defaultFilter = {
  filters: {
    searchQuery: '',
    active: true,
    locationIds: [],
    locationJobNumberIds: []
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const useSummaryParams = () => useSearchParams(['searchQuery']);

const generateInitialQuery = ({ getTableLimit, isAllLimitEnabled, filter, searchQuery }) => {
  const defaultPage = defaultFilter.pagination.page;
  const limit = getTableLimit(PROJECT_LIST_TABLE_PARAMETER);

  const initialQuery = {
    filters: { ...defaultFilter.filters },
    pagination: { limit, page: defaultPage },
    sortRules: filter?.sortRules || defaultFilter.sortRules,
    responseReceived: false
  };

  const setFilters = (customFilters) => ({
    ...initialQuery,
    filters: { ...initialQuery.filters, ...customFilters }
  });

  if (searchQuery) {
    return setFilters({ searchQuery });
  }

  const isAll = isAllLimitEnabled(PROJECT_LIST_TABLE_PARAMETER);
  const page = limit === 100 && isAll ? defaultPage : filter?.pagination?.page || defaultPage;

  return {
    ...initialQuery,
    filters: { ...initialQuery.filters, ...filter?.filters },
    pagination: { limit, page }
  };
};

const generateChips = (criteria, filters = {}) => {
  const { locations } = criteria;

  let newChips = [];

  const filterConfigs = [
    { options: locations, getLabel: (el) => el.siteCode, key: 'locationIds' },
    { options: locations, getLabel: (el) => el.locationJobNumber, key: 'locationJobNumberIds' }
  ];

  filterConfigs.forEach(({ options, getLabel, key }) => {
    if (filters?.[key]?.length) {
      newChips = newChips.concat(handleIdsFilter(filters[key], options, getLabel, key));
    }
  });

  if (!filters?.active) {
    newChips = newChips.concat(handleBooleanFilter('active', 'Inactive'));
  }

  return newChips.filter(Boolean);
};

const removeChip = (filters, deletedChip) => {
  const newFilters = cloneObj(filters);
  const { key, itemId } = deletedChip;

  switch (key) {
    case 'active':
      newFilters[key] = true;
      break;
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }

  return newFilters;
};

const parseQuery = (query) => {
  const payload = {
    filters: { searchQuery: '' },
    pagination: { page: query.pagination.page, limit: query.pagination.limit }
  };

  const setFilters = (key, value) => {
    if (value?.length || value) {
      payload.filters[key] = value;
    }
  };

  const filterKeys = Object.keys(defaultFilter.filters);

  filterKeys.forEach((key) => setFilters(key, query.filters[key]));

  payload.filters.active = query.filters.active;

  if (query.sortRules) {
    payload.sortRules = query.sortRules;
  }

  return payload;
};

const tableConfig = [
  { id: 1, label: 'Project Name', isSortable: true, parameter: 'name' },
  { id: 2, label: 'Project Number', isSortable: true, parameter: 'jobNumber' },
  { id: 3, label: 'Contract №', isSortable: true, parameter: 'contractNumber' },
  { id: 4, label: 'Contract Date', isSortable: true, parameter: 'contractDate' },
  { id: 5, label: 'Description', isSortable: false, parameter: null }
];

export {
  defaultFilter,
  useSummaryParams,
  generateInitialQuery,
  generateChips,
  removeChip,
  parseQuery,
  tableConfig
};
