import { useDispatch } from 'react-redux';

import {
  getProjects,
  setFilter,
  setFilterCriteria,
  getLocations,
  clearState,
  deleteProject,
  getProjectLocations,
  getSingleProject,
  updateProjectLocations
} from 'actions/projectActions';

import { setActiveProject } from 'actions/locationsActions';

export function useProjectActions() {
  const dispatch = useDispatch();

  const getProjectsAction = (q) => dispatch(getProjects(q));

  const setFilterAction = (data) => dispatch(setFilter(data));

  const getFilterCriteriaAction = () =>
    Promise.all([
      dispatch(getLocations({ filters: { active: true, includeUserCheck: true } }))
    ]).then(([locations]) => {
      const criteria = { locations: locations?.items || [] };
      dispatch(setFilterCriteria(criteria));
      return criteria;
    });

  const getProjectAction = (id) => dispatch(getSingleProject(id));

  const deleteProjectAction = (id) => dispatch(deleteProject(id));
  const updateProjectLocationsAction = (data) => dispatch(updateProjectLocations(data));

  const getProjectLocationAction = (filter) => dispatch(getProjectLocations(filter));
  const setActiveProjectAction = (project) => dispatch(setActiveProject(project));

  const clearStateAction = () => dispatch(clearState());

  return {
    getProjectsAction,
    setFilterAction,
    getFilterCriteriaAction,
    getProjectAction,
    deleteProjectAction,
    setActiveProjectAction,
    getProjectLocationAction,
    updateProjectLocationsAction,
    clearStateAction
  };
}
