import React from 'react';
import styles from '../ItemsTableBody.module.scss';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import { StyledTableCell } from 'components/StyledComponents';
import clsx from 'clsx';

function LocationCell({
  options,
  index,
  updateTable,
  defaultValue,
  name,
  isInvalid,
  errorText,
  optionType
}) {
  const onLocationSelect = (name, value, index) => updateTable(name, index, value);

  return (
    <StyledTableCell
      className={clsx(
        name === 'location' ? styles.currentLocationCell : styles.locationCell,
        !!isInvalid && styles.error
      )}>
      <InputSearchWithMultiselect
        name={name}
        defaultValue={defaultValue?.id || ''}
        options={options}
        onFilterSelect={onLocationSelect}
        index={index}
        multiselect={false}
        tableView={true}
        optionType={optionType}
        clearable={name === 'destinationSublocation'}
      />
      {!!errorText && (
        <div className={styles.errorBlock}>
          <span className={styles.side_space}>{errorText}</span>
        </div>
      )}
    </StyledTableCell>
  );
}

export default LocationCell;
