import React, { useRef, useState } from 'react';
import styles from './BodyCells.module.scss';
import { TEAM_MEMBER_DETAILED_PATH } from 'constants/routeConstants';
import { StyledTableCell } from 'components/StyledComponents';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import { useTrackerActions } from 'hooks/TeamMemberTracker';
import { Fade, IconButton, Paper, Popper } from '@material-ui/core';
import LicenseExpirationPopper from './LicenseExpirationPopper/LicenseExpirationPopper';
import { useUserConfig } from 'hooks/useUserConfig';

export default function TeamMemberCell({ data }) {
  const { teamMember, isExpired, color } = data;

  const { isAdminUser, isPersonnelManagerUser } = useUserConfig();

  const [open, setOpen] = useState(false);
  const [popupData, setPopupData] = useState({});

  const iconRef = useRef();

  const { getTeamMemberDetailsAction } = useTrackerActions();

  const clearModal = () => {
    setOpen(false);
    setPopupData({});
  };

  const hide = () => clearModal();

  const getTeamMemberData = () => {
    if (open) {
      clearModal();
    } else {
      getTeamMemberDetailsAction(teamMember.id, false).then((res) => {
        if (res?.id) {
          setPopupData(res);
          setOpen(true);
        }
      });
    }
  };

  return (
    <StyledTableCell style={{ backgroundColor: color || null }} className={styles.memberCell}>
      <Popper open={!!open} anchorEl={() => iconRef.current} placement="bottom-start" transition>
        {({ TransitionProps, placement }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
            timeout={0}>
            <Paper>
              <div className={styles.textPopperBlock}>
                <div className={styles.crossButton} onClick={clearModal}>
                  x
                </div>
                <div className={styles.popperHeader}>
                  <span>{popupData.firstName + ' ' + popupData.lastName}</span>
                </div>
                <div className={styles.popperContent}>
                  <div className={styles.row}>
                    <label>Airport</label>
                    <div className={styles.infoBlock}>
                      <span>{popupData['airport'] || ''}</span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <label>Employment</label>
                    <div className={styles.infoBlock}>
                      <span>
                        {('status' in popupData &&
                          popupData?.status.map(({ name }) => name).join(', ')) ||
                          ''}
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <label>Capacity</label>
                    <div className={styles.infoBlock}>
                      <span>
                        {('capacity' in popupData &&
                          popupData?.capacity.map(({ name }) => name).join(', ')) ||
                          ''}
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <label>Clearance</label>
                    <div className={styles.infoBlock}>
                      <span>{popupData['clearance']?.name || ''}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>

      <div className={styles.memberBlock} onBlur={hide} tabIndex="0">
        <div className={styles.firstBlock}>
          <LicenseExpirationPopper isExpired={isExpired} />
          <LinkComponent
            path={`${TEAM_MEMBER_DETAILED_PATH}/${teamMember.id}`}
            name={teamMember.lastName + ', ' + teamMember.firstName}
            isRedirectAvailable={isAdminUser || isPersonnelManagerUser}
          />
        </div>
        <div className={styles.secondBlock}>
          {teamMember.doNotDrive && <span className={styles.doNotDrive}>(DND)</span>}
          {(isAdminUser || isPersonnelManagerUser) && (
            <IconButton ref={iconRef} className={styles.info_button} onClick={getTeamMemberData}>
              <InfoOutlinedIcon />
            </IconButton>
          )}
        </div>
      </div>
    </StyledTableCell>
  );
}
