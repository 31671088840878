import { createSelector } from 'reselect';

const billOfLadingData = (state) => state.billOfLadingData;

export const selectCurrentBillOfLadingData = () =>
  createSelector([billOfLadingData], (state) => state.currentBoL);

export const selectFilterData = () => createSelector([billOfLadingData], (state) => state.filter);

export const selectFilterCriteriaData = () =>
  createSelector([billOfLadingData], (state) => state.filterCriteria);

export const selectCreationFormData = () =>
  createSelector([billOfLadingData], (state) => state.creationForm);
