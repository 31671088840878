import React from 'react';
import styles from './ErrorBlock.module.scss';
import clsx from 'clsx';

export default function ErrorBlock({ errorText, className }) {
  return (
    !!errorText && (
      <div className={clsx(className && className, styles.errorBlock)}>
        <span>{errorText}</span>
      </div>
    )
  );
}
