import { useSelector } from 'react-redux';
import {
  selectFilterData,
  selectFilterCriteriaData,
  selectCreationFormData
} from 'pages/LooseItems/AssembledBOM/selectors';

export function useAssembledBOMSelector() {
  const filter = useSelector(selectFilterData());
  const filterCriteria = useSelector(selectFilterCriteriaData());

  const creationForm = useSelector(selectCreationFormData());

  return {
    filter,
    filterCriteria,
    creationForm
  };
}
