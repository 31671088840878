import React from 'react';
import styles from './FormInputText.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { reformatFractionValue, reformatInputValue, reformatNumberValue } from 'helpers/AppHelpers';

export default function FormInputText_v2({ name, options, onInputTriggered, classes }) {
  const { control, setValue, clearErrors } = useFormContext();

  const {
    max,
    rule,
    focus,
    type,
    full,
    disableError,
    extraAction,
    onlyNumbers,
    isDisabled,
    index,
    onBlur,
    onFocus,
    skipClearingError = false
  } = options;

  const handleInput = (e, isInvalid) => {
    let value = e.target.value;

    switch (type) {
      case 'quantity':
        value = reformatNumberValue(value);
        value = max ? (value > max ? max : value) : value;
        if (onlyNumbers) {
          value = value || 0;
        }
        break;
      case 'number':
        value = reformatFractionValue(value);
        break;
      default:
        value = reformatInputValue(value, max, rule);
        break;
    }

    !skipClearingError && isInvalid && clearErrors(name);
    setValue(name, value, { shouldDirty: true, shouldTouch: true });
    extraAction && extraAction(name, value, index);
    onInputTriggered && onInputTriggered(name, value, index);
  };

  const handleFocus = (event) => event.target.select();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <div
          className={clsx(
            !!classes && classes,
            styles.inputWrapper,
            !!full && styles.full,
            !!isDisabled && styles.disabled
          )}>
          <TextField
            {...field}
            className={clsx(
              styles.textInput,
              !disableError && error && styles.error,
              type === 'number' && styles.numberType
            )}
            variant="outlined"
            value={type === 'quantity' ? value ?? '' : value}
            onChange={(e) => handleInput(e, !!error?.message)}
            onFocus={onFocus ? onFocus : focus ? handleFocus : undefined}
            onBlur={onBlur}
            type={type === 'number' ? type : 'text'}
            inputProps={{
              inputMode: type === 'number' ? 'decimal' : 'text',
              autoComplete: 'off'
            }}
          />
          {!disableError && !!error && <span>{error.message}</span>}
        </div>
      )}
    />
  );
}
